var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"request-inner-table-container width-100"},[_vm._m(0),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"name","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.driverdetails.driver_name ? item.driverdetails.driver_name : "-")+" ")])]}},{key:"item.est_del_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.estimation_delivery_date && !item.delivered_at ? _vm.formatDate(item.estimation_delivery_date) : "-")+" ")])]}},{key:"item.delivered_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.delivered_at ? _vm.formatDateTime(item.delivered_at) : "-")+" ")])]}},{key:"item.pickup_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.picked_at ? _vm.formatDateTime(item.picked_at) : "-")+" ")])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.fromAddressDetails.block_number ? item.fromAddressDetails.block_number + ", " : "")+" "+_vm._s(item.fromAddressDetails.floor_number ? item.fromAddressDetails.floor_number + ", " : "")+_vm._s(item.fromAddressDetails.address ? item.fromAddressDetails.address : "-")+" ")]),(item.fromAddressDetails.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.fromAddressDetails.cityortown ? item.fromAddressDetails.cityortown + ", " : "")+_vm._s(item.fromAddressDetails.state ? item.fromAddressDetails.state + ", " : "")+_vm._s(item.fromAddressDetails.country ? item.fromAddressDetails.country : "")+" ")]):_vm._e()]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.toAddressDetails.block_number ? item.toAddressDetails.block_number + ", " : "")+" "+_vm._s(item.toAddressDetails.floor_number ? item.toAddressDetails.floor_number + ", " : "")+_vm._s(item.toAddressDetails.address ? item.toAddressDetails.address : "-")+" ")]),(item.toAddressDetails.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.toAddressDetails.cityortown ? item.toAddressDetails.cityortown + ", " : "")+_vm._s(item.toAddressDetails.state ? item.toAddressDetails.state + ", " : "")+_vm._s(item.toAddressDetails.country ? item.toAddressDetails.country : "")+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name",style:({
          color: '#387C17',
        })},[_vm._v(" "+_vm._s(item.started_at && item.picked_at && item.delivered_at ? "Delivered" : item.started_at && item.picked_at ? "Order Picked" : item.started_at ? "Driver Enroute" : "Not yet Started")+" ")]),(item.status && item.status === 'Failed')?_c('div',{staticClass:"attempts mt-2"},[_vm._v(" No response ")]):_vm._e()]}},{key:"item.delivered_image",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"name"},[(item.delivered_image)?_c('img',{directives:[{name:"viewer",rawName:"v-viewer",value:({
            button: true,
            navbar: false,
            title: false,
            toolbar: false,
            tooltip: false,
            movable: false,
            zoomable: false,
            rotatable: false,
            scalable: false,
            transition: true,
            fullscreen: false,
            keyboard: false,
          }),expression:"{\n            button: true,\n            navbar: false,\n            title: false,\n            toolbar: false,\n            tooltip: false,\n            movable: false,\n            zoomable: false,\n            rotatable: false,\n            scalable: false,\n            transition: true,\n            fullscreen: false,\n            keyboard: false,\n          }"}],staticClass:"pod-image cursor-pointer",attrs:{"src":(_vm.serverUrl + "/" + (item.delivered_image)),"alt":"pod-img"}}):_c('span',[_vm._v("-")])])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner-table-header display-flex justify-space-between align-center"},[_c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"table-title ml-2"},[_vm._v("Assignments")])])])}]

export { render, staticRenderFns }