<template>
  <div>
    <CommonToolbar
      title="Drivers"
      :primaryButtonLabel="
        loggedUserPermission.edit_drivers &&
          loggedUserPermission.view_drivers &&
          'Add Driver'
      "
      :secondaryButtonLabel="loggedUserPermission.view_drivers && 'Export'"
      :isSearch="loggedUserPermission.view_drivers"
      @search="onSearch"
      @primaryButtonClick="addClick"
      @secondaryButtonClick="exportClick"
      @filterClicked="filterClicked"
      @resetClicked="resetClicked"
      :isFilter="fromDate && toDate"
      :dateFilterType="dateFilterType"
      :showDateFilter="loggedUserPermission.view_drivers"
    />
    <SubHeader :items="items" />
    <TableSection v-if="loggedUserPermission.view_drivers" :key="tableKey" />
    <NoAccess v-else class="no-content-container" />
    <AddDriver
      :show="showAddDriver"
      @close="close"
      width="928"
      title="Add Driver"
      @saveClicked="refresh"
      :key="addKey"
    />
  </div>
</template>

<script>
import { LOGISTICS_SUB_NAV_ITEMS } from "../../../../constants";
import TableSection from "@/components/Logistics/Drivers/TableSection";
import AddDriver from "@/components/Logistics/Drivers/AddDriver";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LogisticDrivers",
  components: { TableSection, AddDriver },
  data() {
    return {
      items: LOGISTICS_SUB_NAV_ITEMS,
      showAddDriver: false,
      tableKey: 0,
      addKey: 10,
      dateFilterType: [
        {
          name: "Created Date",
          value: "created_at",
        },
        {
          name: "Scheduled Date",
          value: "scheduled_date",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      setSearchQuery: "logistics/driver/setSearchQuery",
      exportCustomers: "logistics/driver/exportCustomers",
      setDateRange: "logistics/driver/setDateRange",
      setPage: "logistics/driver/setPage",
      getLogisticsTabCount: "logistics/count/getLogisticsTabCount",
    }),
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    addClick() {
      this.showAddDriver = true;
    },
    refresh() {
      this.showAddDriver = false;
      this.tableKey += 1;
      this.addKey += 1;
    },
    close() {
      this.showAddDriver = false;
      this.addKey += 1;
    },
    async exportClick() {
      await this.exportCustomers();
    },
    filterClicked(value) {
      this.setDateRange(value);
      this.tableKey += 1;
    },
    resetClicked() {
      this.setDateRange({});
      this.tableKey += 1;
    },
  },
  async created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setDateRange({});
    await this.getLogisticsTabCount();
    this.items[0].count = this.count.requestCount;
    this.items[1].count = this.count.driverCount;
    this.items[2].count = this.count.sortingCenterCount;
  },
  watch: {
    totalDrivers() {
      this.items[1].count = this.totalDrivers;
    },
  },
  computed: {
    ...mapGetters("logistics/driver", ["totalDrivers", "fromDate", "toDate"]),
    ...mapGetters("logistics/authentication", ["loggedUserPermission"]),
    ...mapGetters("logistics/count", ["count"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.driver-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
}
</style>
