<template>
  <div>
    <CommonToolbar
      title="Requests"
      :secondaryButtonLabel="loggedUserPermission.view_requests && 'Export'"
      :isSearch="loggedUserPermission.view_requests"
      @search="onSearch"
      @secondaryButtonClick="exportClick"
    >
      <!-- Filter Slot -->
      <v-edit-dialog ref="requestFilterModal">
        <v-btn
          v-if="loggedUserPermission.view_requests"
          class="mr-4 position-relative"
          icon
          dark
        >
          <img src="@/assets/icons/filter.svg" />
          <div
            class="filter-marker position-absolute"
            v-if="isRequestFilter"
          ></div>
        </v-btn>
        <template v-slot:input>
          <RequestFilter
            @onClose="resetFilterClicked"
            @onFilter="filterClicked"
            :key="filterModalKey"
          />
        </template>
      </v-edit-dialog>
    </CommonToolbar>
    <SubHeader :items="items" />
    <div class="request-table-container">
      <TableSection v-if="loggedUserPermission.view_requests" :key="tableKey" />
      <NoAccess v-else class="no-content-container" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { LOGISTICS_SUB_NAV_ITEMS } from "../../../../constants";
import TableSection from "@/components/Logistics/Requests/TableSection";
import RequestFilter from "@/components/Logistics/Requests/RequestFilter";

export default {
  name: "LogisticRequest",
  components: { TableSection, RequestFilter },
  data() {
    return {
      items: LOGISTICS_SUB_NAV_ITEMS,
      tableKey: 0,
      filterModalKey: 10,
    };
  },
  methods: {
    ...mapActions({
      setSearchQuery: "logistics/requests/setSearchQuery",
      getLogisticsTabCount: "logistics/count/getLogisticsTabCount",
      setPage: "logistics/requests/setPage",
      exportRequests: "logistics/requests/exportRequests",
      setFilterValues: "logistics/requests/setFilterValues",
    }),
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    async exportClick() {
      await this.exportRequests();
    },
    filterClicked() {
      this.setPage(1);
      this.$refs["requestFilterModal"].isActive = false;
      this.tableKey += 1;
    },
    resetFilterClicked() {
      this.$refs["requestFilterModal"].isActive = false;
      this.filterModalKey += 1;
      this.tableKey += 1;
    },
  },
  computed: {
    ...mapGetters("logistics/requests", ["totalRequest", "isRequestFilter"]),
    ...mapGetters("logistics/authentication", ["loggedUserPermission"]),
    ...mapGetters("logistics/count", ["count"]),
  },
  watch: {
    totalRequest() {
      this.items[0].count = this.totalRequest;
    },
  },
  async created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setFilterValues(null);
    await this.getLogisticsTabCount();
    this.items[0].count = this.count.requestCount;
    this.items[1].count = this.count.driverCount;
    this.items[2].count = this.count.sortingCenterCount;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.request-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
}
.filter-marker {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: $primary-color;
  top: -2px;
  right: -3px;
}
</style>
