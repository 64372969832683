var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logistic-table-container pl-7 pr-7 width-100"},[(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center"}):_c('div',[(_vm.totalDrivers === 0)?_c('div',{staticClass:"no-content-container"},[_c('NoContent')],1):_c('v-data-table',{attrs:{"headers":_vm.driverHeaders,"items":_vm.drivers,"expanded":_vm.expanded,"hide-default-footer":"","show-expand":"","item-key":"id","server-items-length":_vm.totalDrivers,"options":{ page: _vm.page, itemsPerPage: _vm.limit }},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){return _vm.updatePagination($event)},"update:items-per-page":function($event){return _vm.updatePerPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","show-first-last-page":"","items-per-page-options":_vm.itemsPerPage},on:{"update:options":updateOptions}})]}},{key:"item.driver_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"display-flex align-center"},[(item.driver_image)?_c('Avatar',{attrs:{"url":(_vm.serverUrl + "/" + (item.driver_image)),"name":item.driver_name,"size":"50","radius":"12"}}):_c('Avatar',{attrs:{"name":item.driver_name,"size":"50","radius":"12"}}),_c('div',{staticClass:"name ml-4"},[_vm._v(" "+_vm._s(item.driver_name ? item.driver_name : "-")+" ")])],1)]}},{key:"item.contact_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.contact_number ? item.contact_number : "-")+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [(item.address)?_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.block_number ? item.block_number + ", " : "")+_vm._s(item.floor_number ? item.floor_number + ", " : "")+_vm._s(item.address)+" ")]):_vm._e(),(item.address)?_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.cityortown ? item.cityortown + ", " : "")+_vm._s(item.state ? item.state + ", " : "")+_vm._s(item.country ? item.country : "")+" ")]):_c('div',{staticClass:"address-1"},[_vm._v("-")])]}},{key:"item.license_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.license_number ? item.license_number : "-")+" ")])]}},{key:"item.license_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.license_type ? item.license_type : "-")+" ")])]}},{key:"item.email_address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.email_address ? item.email_address : "-")+" ")])]}},{key:"item.actions",fn:function(props){return [(props.item.actions)?_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('ActionButton',{staticClass:"mr-4",attrs:{"label":"Deliveries","count":props.item.ordercount,"icon":"delivery","width":"130px","active":props.item.deliverySelected},on:{"click":function($event){return _vm.expandClicked(props, 'Deliveries', props.item.type)}}}),(_vm.loggedUserPermission.edit_drivers)?_c('v-edit-dialog',{attrs:{"return-value":props.item.action},on:{"update:returnValue":function($event){return _vm.$set(props.item, "action", $event)},"update:return-value":function($event){return _vm.$set(props.item, "action", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('InnerMoreOptions',{on:{"editClicked":function($event){return _vm.editClicked(props.item)},"deleteClicked":function($event){return _vm.deleteClicked(props.item)}}})]},proxy:true}],null,true)},[_c('div',{staticClass:"more-options exact-center ml-2 cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])]):_vm._e()],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"inner-table-td disply-flex",attrs:{"colspan":headers.length}},[_c('div',[_c('div',{staticClass:"inner-table-header display-flex justify-space-between align-center"},[_c('div',{staticClass:"display-flex align-center"},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/icons/delivery.svg")}}),_c('div',{staticClass:"table-title ml-2"},[_vm._v("Deliveries")]),_c('SearchInput',{staticClass:"ml-4",attrs:{"value":item.searchValue,"autofocus":true},on:{"change":function($event){return _vm.onSearch($event, {
                      type: 'Deliveries',
                      driver: item,
                    })}}}),(!item.hideFilter)?_c('v-edit-dialog',{ref:"driverFilterModal",scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('DriverFilter',{key:_vm.driverFilterKey,on:{"onClose":function($event){return _vm.filterClicked({
                          type: 'Deliveries',
                          driver: item,
                          isFilter: false,
                        })},"onFilter":function($event){return _vm.filterClicked({
                          type: 'Deliveries',
                          driver: item,
                          isFilter: true,
                        })}}})]},proxy:true}],null,true)},[_c('v-btn',{staticClass:"ml-4 position-relative",attrs:{"icon":"","dark":""}},[_c('img',{attrs:{"src":require("@/assets/icons/filter.svg")}}),(_vm.isDeliveryFilter)?_c('div',{staticClass:"filter-marker position-absolute"}):_vm._e()])],1):_vm._e(),_c('Button',{staticClass:"ml-4",attrs:{"label":"Export","type":"secondary","width":"137px"},on:{"click":function($event){return _vm.exportDelivery(item)}}})],1)])]),(_vm.isTableLoading)?_c('Spinner',{staticClass:"inner-spinner-class exact-center"}):_c('InnerTable',{key:_vm.innerTableKey,attrs:{"item":item,"selectedType":item.type,"headers":item.innerHeaders,"items":item.innerItems,"totalItem":item.totalItem,"page":item.page,"isFilter":item.isFilter,"searchValue":item.searchValue},on:{"updatePagination":_vm.updateInnerTablePage}})],1)]}}])})],1),_c('AddDriver',{key:_vm.addKey,attrs:{"title":"Edit Driver","show":_vm.showAddDriver,"width":"928","isEdit":true,"item":_vm.selectedItem},on:{"close":function($event){_vm.showAddDriver = false},"saveClicked":_vm.refresh}}),_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete Driver","cancel":"Cancel","content":"Are you sure you want to delete the selected Driver ? ","accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }