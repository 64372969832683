<template>
  <div class="assign-container pa-12" :key="refreshKey">
    <h1>Assign</h1>
    <div class="add-form mt-6">
      <v-row no-gutters v-if="type === 'Parcel at sorting center'">
        <v-col>
          <SelectField
            label="Select Sorting Center"
            :required="true"
            itemText="sortingCenter_nameWithAddress"
            :value="formData.sorting_center"
            @change="getFormData($event, 'sorting_center')"
            :data-set="sortingCenterDataSet"
            :loading="sortingCenterLoading"
            :disabled="type === 'Parcel at sorting center'"
            :isInfinteScroll="true"
            @onScroll="onSortingCenterScroll"
            @onSearch="onSortingCenterSearch"
            @focus="getAllSortingCenter"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <SelectField
            label="Delivery Type"
            :required="true"
            :value="formData.delivery_type"
            @change="getFormData($event, 'delivery_type')"
            :data-set="deliveryTypeDataSet"
            :disabled="type === 'Parcel at sorting center'"
          />
        </v-col>
        <v-col class="ml-3">
          <SelectField
            label="Assign Driver"
            itemText="driver_name"
            :required="true"
            :loading="driverLoading"
            :value="formData.driver"
            @change="getFormData($event, 'driver')"
            :data-set="driverDataSet"
            :isInfinteScroll="true"
            @onScroll="onDriverScroll"
            @onSearch="onDriverSearch"
            @focus="getAllDrivers"
          />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="type === 'Parcel ready for shipping'">
        <v-col>
          <DatePicker
            icon="calendar"
            :minDate="minDate"
            :maxDate="maxDate"
            :dateValue="formData.pickup_date"
            @change="getFormData($event, 'pickup_date')"
            label="Pickup date"
            :required="true"
          />
        </v-col>
        <v-col class="ml-3">
          <DatePicker
            icon="calendar"
            :minDate="pickupMinDate"
            :maxDate="maxDate"
            :dateValue="formData.estimation_delivery_date"
            @change="getFormData($event, 'estimation_delivery_date')"
            label="Estimated Delivery Date"
            :required="true"
            :key="dateKey"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        v-if="
          formData.delivery_type === 'Sorting then Delivery' &&
            type !== 'Parcel at sorting center'
        "
      >
        <v-col>
          <SelectField
            label="Select Sorting Center"
            :required="true"
            itemText="sortingCenter_nameWithAddress"
            :value="formData.sorting_center"
            @change="getFormData($event, 'sorting_center')"
            :data-set="sortingCenterDataSet"
            :loading="sortingCenterLoading"
            :disabled="type === 'Parcel at sorting center'"
            :isInfinteScroll="true"
            @onScroll="onSortingCenterScroll"
            @onSearch="onSortingCenterSearch"
            @focus="getAllSortingCenter"
          />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="type === 'Parcel at sorting center'">
        <v-col>
          <DatePicker
            icon="calendar"
            :minDate="minDate"
            :maxDate="maxDate"
            :dateValue="formData.pickup_date"
            @change="getFormData($event, 'pickup_date')"
            label="Pickup date"
            :required="true"
          />
        </v-col>
        <v-col class="ml-3">
          <DatePicker
            icon="calendar"
            :minDate="pickupMinDate"
            :maxDate="maxDate"
            :dateValue="formData.estimation_delivery_date"
            @change="getFormData($event, 'estimation_delivery_date')"
            label="Estimated Delivery Date"
            :required="true"
            :key="dateKey"
          />
        </v-col>
      </v-row>
    </div>
    <div class="display-flex width-100 justify-end align-center">
      <Button label="Close" type="cancel" width="94px" @click="closeClicked" />
      <Button
        class="ml-3"
        label="Save"
        type="save"
        width="94px"
        :disabled="isSubmitDisabled()"
        @click="onSave"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "AssignDriverModal",
  props: ["type", "id", "item"],
  components: {},
  mixins: [validationMixin],
  validations: {
    formData: {
      // sorting_center: { required },
      driver: { required },
      delivery_type: { required },
      estimation_delivery_date: { required },
      pickup_date: { required },
    },
  },
  computed: {
    ...mapGetters("logistics/driver", ["drivers", "totalDrivers", "page"]),
    ...mapGetters("logistics/sortingCenter", [
      "sortingCenters",
      "totalSortingCenter",
      "sortingCenterPage,"
    ]),
    ...mapGetters("logistics/authentication", ["loggedUser"]),
  },
  data() {
    return {
      formData: {
        sorting_center: "",
        driver: "",
        delivery_type: "",
        estimation_delivery_date: "",
        pickup_date: "",
      },
      minDate: new Date().toISOString().substr(0, 10),
      maxDate: "",
      pickupMinDate: new Date().toISOString().substr(0, 10),
      driverLoading: false,
      sortingCenterLoading: false,
      deliveryTypeDataSet: ["Direct Delivery", "Sorting then Delivery"],
      driverDataSet: [],
      sortingCenterDataSet: [],
      isButtonDisabled: true,
      dateKey: 0,
      refreshKey: 10,
    };
  },
  methods: {
    ...mapActions({
      getDrivers: "logistics/driver/getDrivers",
      setDriverPage: "logistics/driver/setPage",
      setDriverSearch: "logistics/driver/setSearchQuery",
      getSortingCenters: "logistics/sortingCenter/getSortingCenters",
      setSortingCenterPage: "logistics/sortingCenter/setPage",
      setSortingCenterSearch: "logistics/sortingCenter/setSearchQuery",
      assignDriver: "logistics/requests/assignDriver",
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      if (fieldName === "pickup_date") {
        this.pickupMinDate = moment(value)
          .add(1, "day")
          .toISOString()
          .substr(0, 10);
        if (
          this.formData.estimation_delivery_date &&
          moment(this.formData.pickup_date).isAfter(
            this.formData.estimation_delivery_date
          )
        ) {
          this.formData.estimation_delivery_date = "";
        }
        this.dateKey += 1;
      }
      if (this.formData.delivery_type === "Sorting then Delivery") {
        this.isButtonDisabled = this.formData.sorting_center === "";
      } else if (this.formData.delivery_type !== "Sorting then Delivery") {
        this.isButtonDisabled = false;
      }
    },
    isSubmitDisabled() {
      return this.$v.$invalid || this.isButtonDisabled;
    },
    async onSave() {
      let data = {
        driver_id: this.formData.driver.id,
        delivery_type: this.formData.delivery_type,
        estimation_delivery_date: this.formData.estimation_delivery_date,
        pickup_date: this.formData.pickup_date,
      };
      if (data.delivery_type === "Sorting then Delivery") {
        data.sortingcenter_id = this.formData.sorting_center.id;
      }
      await this.assignDriver({ data, id: this.id });
      this.$emit("refresh");
    },
    closeClicked() {
      this.$emit("closeClicked");
    },
    async onDriverScroll() {
      if (this.totalDrivers > this.driverDataSet.length) {
        this.driverLoading = true;
        this.setDriverPage(this.page + 1);
        await this.getDrivers();
        this.driverLoading = false;
        this.driverDataSet = [...this.driverDataSet, ...this.drivers];
      }
    },
    async onSortingCenterScroll() {
      if (this.totalSortingCenter > this.sortingCenterDataSet.length) {
        this.sortingCenterLoading = true;
        this.setSortingCenterPage(this.sortingCenterPage + 1);
        await this.getSortingCenters(this.loggedUser);
        this.sortingCenterLoading = false;
        this.sortingCenterDataSet = [
          ...this.sortingCenterDataSet,
          ...this.sortingCenters,
        ];
      }
    },
    async onSortingCenterSearch(value) {
      this.sortingCenterLoading = true;
      this.setSortingCenterSearch(value);
      await this.getSortingCenters(this.loggedUser);
      this.sortingCenterLoading = false;
      this.sortingCenterDataSet = this.sortingCenters;
    },
    async onDriverSearch(value) {
      this.driverLoading = true;
      this.setDriverSearch(value);
      await this.getDrivers();
      this.driverLoading = false;
      this.driverDataSet = [...this.driverDataSet, ...this.drivers];
    },
    async getAllDrivers() {
      this.driverLoading = true;
      this.setDriverPage(1);
      this.setDriverSearch("");
      await this.getDrivers();
      this.driverDataSet = this.drivers;
      this.driverLoading = false;
    },
    async getAllSortingCenter() {
      this.sortingCenterLoading = true;
      this.setSortingCenterPage(1);
      this.setSortingCenterSearch("");
      await this.getSortingCenters(this.loggedUser);
      this.sortingCenterDataSet = this.sortingCenters;
      this.sortingCenterLoading = false;
    },
  },
  async created() {
    await this.getAllDrivers();
    if (this.type === "Parcel at sorting center") {
      await this.getAllSortingCenter();
      this.formData.delivery_type = "Direct Delivery";
      this.item.sortingcenterdetails.sortingCenter_nameWithAddress = `${this.item.sortingcenterdetails.sortingcenter_name}, ${this.item.sortingcenterdetails.address}, ${this.item.sortingcenterdetails.cityortown}`;
      this.sortingCenterDataSet.push(this.item.sortingcenterdetails);
      this.formData.sorting_center = this.item.sortingcenterdetails;
      this.refreshKey += 1;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.assign-container {
  width: 688px;
  height: 420px;
  background: $white-color;
  h1 {
    font-weight: 500;
    font-size: 1.125rem;
    color: #000000;
  }
  .add-form {
    font-weight: 500;
    font-size: 13px;
    color: $secondary-color;
  }
}
</style>
