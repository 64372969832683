<template>
  <div class="request-container pl-14 pr-14" ref="tableComponent">
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="totalRequest === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="requestHeaders"
        :items="requests"
        :expanded.sync="expanded"
        item-key="id"
        :item-class="itemRowBackground"
        hide-default-footer
        @update:page="updatePagination($event)"
        :server-items-length="totalRequest"
        :options="{ page: page, itemsPerPage: limit }"
        @update:items-per-page="updatePerPage($event)"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
          />
        </template>
        <template v-slot:item.tracking_id="props">
          <div class="display-flex align-center">
            <div
              class="expand-icon mr-4 cursor-pointer"
              @click="expandClicked(props, 'Assignment', props.item.type)"
            >
              <img
                v-if="!props.item.assignmentSelected"
                src="@/assets/icons/table-expansion-closed.svg"
                alt="expand-icon"
              />
              <img
                v-else
                src="@/assets/icons/table-expansion-open.svg"
                alt="expand-icon"
              />
            </div>
            <div class="name">
              {{ props.item.tracking_id ? props.item.tracking_id : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.pickup_address="{ item }">
          <!-- <div
            v-if="
              item.delivery_type !== 'Direct Delivery' &&
                item.sortingcenterdetails
            "
          >
            <div class="address-1">
              {{
                item.sortingcenterdetails
                  ? item.sortingcenterdetails.address
                  : "-"
              }}
            </div>
            <div class="address-2" v-if="item.sortingcenterdetails.address">
              {{
                item.sortingcenterdetails.cityortown
                  ? item.sortingcenterdetails.cityortown + ", "
                  : ""
              }}{{
                item.sortingcenterdetails.state
                  ? item.sortingcenterdetails.state + ", "
                  : ""
              }}{{
                item.sortingcenterdetails.country
                  ? item.sortingcenterdetails.country
                  : ""
              }}
            </div>
          </div> -->
          <div>
            <div class="address-1">
              {{
                item.warehousedetails.block_number
                  ? item.warehousedetails.block_number + ","
                  : ""
              }}{{
                item.warehousedetails.floor_number
                  ? item.warehousedetails.floor_number + ","
                  : "-"
              }}{{
                item.warehousedetails ? item.warehousedetails.address : "-"
              }}
            </div>
            <div class="address-2" v-if="item.warehousedetails.address">
              {{
                item.warehousedetails.cityortown
                  ? item.warehousedetails.cityortown + ", "
                  : ""
              }}{{
                item.warehousedetails.state
                  ? item.warehousedetails.state + ", "
                  : ""
              }}{{
                item.warehousedetails.country
                  ? item.warehousedetails.country
                  : ""
              }}
            </div>
          </div>
        </template>

        <template v-slot:item.delivery_address="{ item }">
          <div class="address-1">
            {{
              item.customerdetails.block_number
                ? item.customerdetails.block_number + ","
                : ""
            }}{{
              item.customerdetails.floor_number
                ? item.customerdetails.floor_number + ","
                : "-"
            }}{{ item.customerdetails ? item.customerdetails.address : "-" }}
          </div>
          <div class="address-2" v-if="item.customerdetails">
            {{
              item.customerdetails.cityortown
                ? item.customerdetails.cityortown + ", "
                : ""
            }}{{
              item.customerdetails.state
                ? item.customerdetails.state + ", "
                : ""
            }}{{
              item.customerdetails.country ? item.customerdetails.country : ""
            }}
          </div>
        </template>

        <template v-slot:item.product_quantity="{ item }">
          <div class="name">
            {{
              item.ordertrackingdetails.product_quantity
                ? item.ordertrackingdetails.product_quantity
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.uom="{ item }">
          <div class="name">
            {{
              item.ordertrackingdetails.uom
                ? item.ordertrackingdetails.uom
                : "-"
            }}
          </div>
        </template>
        <template v-slot:item.expected_delivery_date="{ item }">
          <div class="name">
            {{
              item.ordertrackingdetails.expected_delivery_date
                ? formatProductDate(
                    item.ordertrackingdetails.expected_delivery_date
                  )
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.pickup_date="{ item }">
          <div class="name">
            {{ item.pickup_date ? formatProductDate(item.pickup_date) : "-" }}
          </div>
        </template>

        <template v-slot:item.est_del_date="{ item }">
          <div class="name">
            {{
              item.estimation_delivery_date && item.status !== "Order delivered"
                ? formatProductDate(item.estimation_delivery_date)
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.assigned_driver="{ item }">
          <div class="name">
            {{ item.driverdetails ? item.driverdetails.driver_name : "-" }}
          </div>
        </template>

        <template v-slot:item.delivery_type="{ item }">
          <div
            class="name"
            v-if="
              item.delivery_type && item.status !== 'Parcel at sorting center'
            "
          >
            {{ item.delivery_type }}
          </div>
          <v-edit-dialog
            :ref="'assignDriver' + item.id"
            v-else
            :return-value.sync="item.delivery_type"
            persistent
          >
            <div class="name align-center display-flex">
              <div
                :style="{
                  color:
                    item.status === 'Parcel ready for shipping'
                      ? '#D2854C'
                      : '#D24C8D',
                }"
              >
                Unassigned
              </div>
              <img
                v-if="item.status === 'Parcel ready for shipping'"
                src="@/assets/icons/pencil-rfs.svg"
                alt="request-assign"
                class="ml-2"
              />
              <img
                v-if="item.status === 'Parcel at sorting center'"
                src="@/assets/icons/pencil-iisc.svg"
                alt="request-assign"
                class="ml-2"
              />
              <img
                v-if="
                  getDateforSameCondition(item) ||
                    getDateforAfterCondition(item)
                "
                class="alert"
                src="@/assets/icons/alert.png"
                alt="alert"
              />
            </div>
            <template v-slot:input>
              <AssignDriverModal
                :type="item.status"
                :id="item.id"
                :item="item"
                @closeClicked="closeAssignModal(item.id)"
                @refresh="refresh"
                :key="assignedKey"
              />
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.status="props">
          <div class="name" :style="{ color: orderStatus[props.item.status] }">
            {{ props.item.status ? props.item.status : "-" }}
          </div>
        </template>

        <template v-slot:item.merchantcompany="props">
          <div class="logistic-badge unassigned exact-center">
            {{
              props.item.merchantcompany && props.item.merchantcompany.name
                ? props.item.merchantcompany.name
                : "-"
            }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="inner-table-td disply-flex" :colspan="headers.length">
            <Spinner
              class="inner-spinner-class exact-center"
              v-if="isTableLoading"
            />
            <InnerTable
              v-else
              :item="item"
              :selectedType="item.type"
              :headers="item.innerHeaders"
              :items="item.innerItems"
              :key="innerTableKey"
            />
          </td>
        </template>

        <template v-slot:item.actions="props">
          <div
            class="action-buttons display-flex align-center"
            v-if="props.item.actions"
          >
            <v-edit-dialog :return-value.sync="props.item.action">
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions @deleteClicked="deleteClicked" />
              </template>
            </v-edit-dialog>
          </div>
        </template>
      </v-data-table>
    </div>
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Request`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Request ? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { ORDER_STATUS, ITEMS_PER_PAGE } from "../../../../constants";
import InnerTable from "@/components/Logistics/Requests/InnerTable";
import AssignDriverModal from "@/components/Logistics/Requests/AssignDriverModal";

export default {
  name: "LogisticRequestTable",
  components: { InnerTable, AssignDriverModal },
  data() {
    return {
      isLoading: false,
      deleteDialog: false,
      itemsPerPage: ITEMS_PER_PAGE,
      innerTableKey: 10,
      assignedKey: 30,
      isTableLoading: false,
      requestHeaders: [
        {
          text: "Tracking Id",
          align: "start",
          sortable: false,
          value: "tracking_id",
          width: "150px",
        },
        {
          text: "Delivery Type",
          align: "start",
          sortable: false,
          value: "delivery_type",
          width: "180px",
        },
        {
          text: "Merchant",
          align: "start",
          sortable: false,
          value: "merchantcompany",
          width: "250px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
          width: "240px",
        },
        {
          text: "Pickup Address",
          align: "start",
          sortable: false,
          value: "pickup_address",
          width: "240px",
        },
        {
          text: "Delivery Address",
          align: "start",
          sortable: false,
          value: "delivery_address",
          width: "240px",
        },
        {
          text: "Qty",
          align: "start",
          sortable: false,
          value: "product_quantity",
          width: "30px",
        },
        {
          text: "UOM",
          align: "start",
          sortable: false,
          value: "uom",
          width: "150px",
        },
        {
          text: "Exp del date",
          align: "start",
          sortable: false,
          value: "expected_delivery_date",
          width: "150px",
        },
        {
          text: "Assigned Driver",
          align: "start",
          sortable: false,
          value: "assigned_driver",
          width: "150px",
        },
        {
          text: "Pick up Date",
          align: "start",
          sortable: false,
          value: "pickup_date",
          width: "150px",
        },
        {
          text: "Est Del Date",
          align: "start",
          sortable: false,
          value: "est_del_date",
          width: "150px",
        },
      ],
      assignmentHeaders: [
        {
          text: "Driver",
          align: "start",
          sortable: false,
          value: "driver",
        },
        {
          text: "From",
          align: "start",
          sortable: false,
          value: "from",
          width: "250px",
        },
        {
          text: "To",
          align: "start",
          sortable: false,
          value: "to",
          width: "250px",
        },
        {
          text: "Pickup date",
          align: "start",
          sortable: false,
          value: "pickup_date",
        },
        {
          text: "Estimated Delivery Date",
          align: "start",
          sortable: false,
          value: "est_del_date",
        },
        {
          text: "Delivered Date",
          align: "start",
          sortable: false,
          value: "delivered_at",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "Proof of Delivery",
          align: "center",
          sortable: false,
          value: "delivered_image",
        },
      ],
      expanded: [],
    };
  },
  methods: {
    ...mapActions({
      getRequests: "logistics/requests/getRequests",
      setPage: "logistics/requests/setPage",
      setLimit: "logistics/requests/setLimit",
      getAssignments: "logistics/assignments/getAssignments",
    }),
    closeAssignModal(id) {
      this.$refs["assignDriver" + id].isActive = false;
      this.assignedKey += 1;
    },
    async updatePagination(value) {
      this.expanded = [];
      this.setPage(value);
      await this.fetchRequests();
    },
    async updatePerPage(value) {
      this.expanded = [];
      this.setLimit(value);
      await this.fetchRequests();
    },
    async refresh() {
      await this.fetchRequests();
      this.assignedKey += 1;
    },
    async fetchRequests() {
      this.isLoading = true;
      await this.getRequests();
      this.isLoading = false;
    },
    itemRowBackground(item) {
      let classes = "";
      let today = moment(new Date()).format("MM-DD-YYYY");
      let expectedDate = moment(
        item?.ordertrackingdetails?.expected_delivery_date
      ).format("MM-DD-YYYY");
      let estimatedDate =
        item.estimation_delivery_date ||
        moment(item.estimation_delivery_date).format("MM-DD-YYYY");
      if (
        item.status !== "Parcel ready for shipping" ||
        item.status !== "Parcel at sorting center"
      ) {
        if (
          moment(today).isAfter(estimatedDate) &&
          item.status !== "Order delivered"
        ) {
          classes += "bg-driver-delay";
        }
      }
      if (!item.delivery_type || item.status === "Parcel at sorting center") {
        if (
          moment(today).isSame(expectedDate) ||
          moment(today).isAfter(expectedDate)
        ) {
          classes += " bg-unassigned-exceed";
        } else {
          classes += " bg-unassigned";
        }
      }
      if (item.delivery_type && item.status !== "Parcel at sorting center") {
        if (moment(today).isSame(estimatedDate)) {
          classes += " bg-today";
        }
      }
      return classes;
    },
    formatDateTime(date) {
      return moment(date).format("Do MMM, YYYY hh:mm a");
    },
    formatProductDate(date) {
      return moment(date).format("Do MMM,YY");
    },
    async expandClicked(slotData, type, selectedType) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1 && selectedType === type) {
        this.expanded.splice(indexExpanded, 1);
        this.requests.map((item) => {
          if (item.id === slotData.item.id) {
            item.assignmentSelected = false;
            item.selected = false;
          }
        });
      } else {
        this.expanded = [];
        this.expanded.push(slotData.item);
        this.requests.map(async (item) => {
          if (item.id === slotData.item.id) {
            switch (type) {
              case "Assignment":
                item.assignmentSelected = true;
                item.selected = true;
                item.innerHeaders = this.assignmentHeaders;
                this.isTableLoading = true;
                await this.getAssignments(item);
                item.innerItems = this.assignments;
                item.type = type;
                this.innerTableKey += 1;
                this.isTableLoading = false;
                break;
            }
          } else {
            item.assignmentSelected = false;
            item.selected = false;
          }
        });
      }
    },
    deleteClicked() {
      this.deleteDialog = true;
    },
    onDeleteConfirm() {
      this.deleteDialog = false;
    },
    onDeleteCancel() {
      this.deleteDialog = false;
    },
    getDateforSameCondition(item) {
      let today = moment(new Date()).format("MM-DD-YYYY");
      let expectedDate = moment(
        item?.ordertrackingdetails?.expected_delivery_date
      ).format("MM-DD-YYYY");
      if (moment(today).isSame(expectedDate)) {
        return true;
      }
      return false;
    },
    getDateforAfterCondition(item) {
      let today = moment(new Date()).format("MM-DD-YYYY");
      let expectedDate = moment(
        item?.ordertrackingdetails?.expected_delivery_date
      ).format("MM-DD-YYYY");
      if (moment(today).isAfter(expectedDate)) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters("logistics/requests", [
      "requests",
      "totalRequest",
      "page",
      "limit",
    ]),
    ...mapGetters("logistics/assignments", ["assignments"]),
    ...mapGetters("logistics/authentication", ["loggedUserPermission"]),
  },
  async created() {
    this.orderStatus = ORDER_STATUS;
    this.expanded = [];
    await this.fetchRequests();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}
::v-deep .v-small-dialog__content {
  width: auto !important;
}

::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.inner-table-td {
  background: $white-color !important;
}
.alert {
  width: 53px;
}
.request-container {
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .unassigned {
    background: #ffeddf;
    font-weight: 600;
    font-size: 12px;
  }
  .logistic-badge {
    width: 160px;
    padding: 10px;
    border-radius: 6px;
  }
  .logistic {
    background: rgba(212, 5, 17, 0.1);
    font-weight: 600;
    font-size: 12px;
  }
  ::v-deep .bg-driver-delay {
    background: #ffd7d7 !important;
  }
  ::v-deep .bg-unassigned-exceed {
    background: #fffac9 !important;
  }
  ::v-deep .bg-unassigned {
    background: #efffe6 !important;
  }
  ::v-deep .bg-today {
    background: #fff5e8 !important;
  }
}
</style>
