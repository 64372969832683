<template>
  <div>
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <v-dialog :value="show" persistent :max-width="width">
      <div>
        <!-- title -->
        <div
          class="add-user-title pt-8 pl-6 pr-6 display-flex width-100 align-center justify-space-between"
        >
          {{ title }}
          <v-btn @click="onCancel" icon dark>
            <img src="@/assets/icons/close.svg" />
          </v-btn>
        </div>
        <!-- body -->
        <v-row class="no-gutters mt-4">
          <v-col class="col-3">
            <ImageUpload
              placeholder="user-icon"
              label="Driver Image"
              :src="imgFile"
              @fileData="getProfileData"
              @removePic="removeProfilePic"
            />
          </v-col>
          <v-col>
            <v-row class="no-gutters mt-2 ml-6 mr-6">
              <v-col>
                <v-row no-gutters>
                  <v-col
                    ><TextField
                      @change="getFormData($event, 'driver_name')"
                      label="Driver Name"
                      :required="true"
                      :value="formData.driver_name"
                      :errorMessages="nameErrors"
                      :error="nameErrors && nameErrors.length > 0"
                      @input="$v.formData.driver_name.$touch()"
                      @blur="$v.formData.driver_name.$touch()"
                  /></v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="4">
                        <DialCode
                          :value="this.formData.extension"
                          @change="getFormData($event, 'extension')"
                        />
                      </v-col>
                      <v-col cols="8">
                        <TextField
                          label="Mobile No."
                          :value="formData.contact_number"
                          :required="true"
                          @change="getFormData($event, 'contact_number')"
                          input-type="Number"
                          min="0"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="ml-3">
                    <TextField
                      label="License Type"
                      :value="formData.license_type"
                      :required="true"
                      @change="getFormData($event, 'license_type')"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <TextField
                      label="License No."
                      :value="formData.license_number"
                      :required="true"
                      @change="getFormData($event, 'license_number')"
                    />
                  </v-col>
                  <v-col class="ml-3">
                    <TextField
                      label="Email ID"
                      :value="formData.email_address"
                      :errorMessages="emailErrors"
                      :error="emailErrors && emailErrors.length > 0"
                      @change="getFormData($event, 'email_address')"
                      @input="$v.formData.email_address.$touch()"
                      @blur="$v.formData.email_address.$touch()"
                  /></v-col>
                </v-row>
                <!--         <v-row no-gutters>
                  <v-col
                    ><TextField
                      label="Post Code"
                      :value="formData.postalcode"
                      @input="getFormData($event, 'postalcode')"
                      :debounce="true"
                      input-type="Number"
                      min="0"
                  /></v-col>
                  <v-col class="ml-3"
                    ><SelectField
                      label="Country"
                      :value="formData.country"
                      :data-set="countryDataSet"
                      @change="getFormData($event, 'country')"
                      :key="countryKey"
                  /></v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    ><TextField
                      label="State"
                      :value="formData.state"
                      @change="getFormData($event, 'state')"
                      :key="stateKey"
                  /></v-col>
                  <v-col class="ml-3">
                    <TextField
                      label="City"
                      :value="formData.cityortown"
                      @change="getFormData($event, 'cityortown')"
                      :key="cityKey"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <TextArea
                      label="Address"
                      rows="5"
                      :value="formData.address"
                      @change="getFormData($event, 'address')"
                      :key="addressKey"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    ><TextField
                      label="Building Name/Block Number"
                      :value="formData.block_number"
                      @change="getFormData($event, 'block_number')"
                  /></v-col>
                  <v-col class="ml-3"
                    ><TextField
                      label="Floor - Unit Number"
                      :value="formData.floor_number"
                      @change="getFormData($event, 'floor_number')"
                  /></v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- footer -->
        <v-row class="footer-section pb-5 no-gutters">
          <v-col class="justify-space-between align-center ml-6 mr-6">
            <div class="note">
              If no Email ID is given, Password will be sent to your Mobile No.
            </div>
            <div class="display-flex">
              <Button
                label="Close"
                type="cancel"
                width="94px"
                @click="onCancel"
              />
              <Button
                class="ml-3"
                label="Save"
                type="save"
                width="94px"
                @click="onSave"
                :disabled="isSubmitDisabled() || isLoading"
                :loading="isLoading"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, helpers } from "vuelidate/lib/validators";
import { Country } from "country-state-city";
import { DEFAULT_COUNTRY, SERVER_URL, NAME_REGEX } from "../../../../constants";
import { mapActions, mapGetters } from "vuex";
import { getAddressData } from "../../../services/utils.js";
const nameChecker = helpers.regex("urlValidate", NAME_REGEX);

export default {
  name: "AddDriver",
  mixins: [validationMixin],
  validations: {
    formData: {
      driver_name: { required, nameChecker },
      license_number: { required },
      extension: { required },
      license_type: { required },
      contact_number: { required },
      email_address: { email },
    },
  },
  props: ["show", "width", "title", "isEdit", "item"],
  data() {
    return {
      serverUrl: SERVER_URL,
      isLoading: false,
      markers: [],
      formData: {
        driver_name: "",
        license_number: "",
        license_type: "",
        contact_number: "",
        extension: "65",
        block_number: "",
        floor_number: "",
        postalcode: "",
        country: "",
        state: "",
        cityortown: "",
        address: "",
        email_address: "",
        picture: "",
        driver_image: "",
      },
      countryDataSet: [],
      stateDataSet: [],
      cityDataSet: [],
      imgFile: "",
      isNotification: false,
      notificationMessage: "",
      notificationKey: 0,
      stateDisabled: false,
      countryKey: 10,
      stateKey: 20,
      cityKey: 30,
      addressKey: 70,
    };
  },
  methods: {
    ...mapActions({
      addDriver: "logistics/driver/addDriver",
      updateDriver: "logistics/driver/updateDriver",
    }),
    onCancel() {
      this.$emit("close");
    },
    async onSave() {
      this.isLoading = true;
      try {
        const data = new FormData();
        if (this.formData.driver_name)
          data.append("driver_name", this.formData.driver_name);
        if (this.loggedUser.company_id)
          data.append("company_id", this.loggedUser.company_id);
        if (this.formData.license_number)
          data.append("license_number", this.formData.license_number);
        if (this.formData.license_type)
          data.append("license_type", this.formData.license_type);
        if (this.formData.block_number)
          data.append("block_number", this.formData.block_number);
        if (this.formData.floor_number)
          data.append("floor_number", this.formData.floor_number);
        if (this.formData.postalcode)
          data.append("postalcode", this.formData.postalcode);
        if (this.formData.country)
          data.append("country", this.formData.country.name);
        if (this.formData.state) data.append("state", this.formData.state);
        if (this.formData.cityortown)
          data.append("cityortown", this.formData.cityortown);
        if (this.formData.address)
          data.append("address", this.formData.address);
        data.append("email_address", this.formData.email_address ? this.formData.email_address : "");
        if (this.formData.extension)
          data.append("extension", this.formData.extension);
        if (this.formData.contact_number)
          data.append("contact_number", this.formData.contact_number);
        data.append(
          "driver_image",
          this.formData.driver_image ? this.formData.driver_image : null
        );
        if (this.formData.picture)
          data.append("picture", this.formData.picture);
        this.isEdit
          ? await this.updateDriver({ id: this.item.id, data: data })
          : await this.addDriver(data);
        this.isLoading = false;
        this.$emit("saveClicked");
      } catch (err) {
        this.notificationMessage = err.response.data.message;
        this.isNotification = true;
        this.notificationKey += 1;
        this.isLoading = false;
      }
    },
    async getFormData(value, fieldName) {
      if (fieldName === "postalcode") {
        await getAddressData(value, (address) => {
          const country = this.countryDataSet.find(
            (obj) => obj.name === address.country
          );
          this.formData.state = address.administrative_area_level_1
            ? address.administrative_area_level_1
            : address.locality;
          this.formData.country = country;
          this.formData.cityortown = address.administrative_area_level_2;
          this.formData.address = address.fullAddress
            ? address.fullAddress
            : "";
          this.countryKey += 1;
          this.stateKey += 1;
          this.cityKey += 1;
          this.addressKey += 1;
        });
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    setDefaultCountry() {
      const country = this.countryDataSet.find(
        (obj) => obj.name === DEFAULT_COUNTRY
      );
      this.formData.country = country;
      this.formData.state = DEFAULT_COUNTRY;
      this.formData.cityortown = DEFAULT_COUNTRY;
    },
    getProfileData(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener("load", () => {
        this.formData.picture = files[0];
        this.imgFile = fr.result;
        this.formData.driver_image = files[0].name;
      });
    },
    removeProfilePic() {
      this.imgFile = "";
      this.formData.picture = "";
      this.formData.driver_image = "";
    },
    setEditFormData() {
      this.formData.driver_name = this.item?.driver_name;
      this.formData.block_number = this.item?.block_number;
      this.formData.floor_number = this.item?.floor_number;
      this.formData.postalcode = this.item.postalcode;
      this.formData.country = country;
      const country = this.countryDataSet.find(
        (obj) => obj.name === this.item.country
      );
      this.formData.country = country;
      this.formData.state = this.item.state ? this.item.state : "";
      this.formData.extension = this.item?.extension;
      this.formData.cityortown = this.item?.cityortown
        ? this.item.cityortown
        : "";
      this.formData.address = this.item?.address;
      this.formData.email_address = this.item?.email_address;
      this.formData.contact_number = this.item?.contact_number;
      this.formData.license_number = this.item?.license_number;
      this.formData.license_type = this.item?.license_type;
      this.formData.driver_image = this.item?.driver_image;
      this.imgFile = this.item.driver_image
        ? `${this.serverUrl}/${this.item.driver_image}`
        : "";
    },
  },
  computed: {
    ...mapGetters("logistics/authentication", ["loggedUser"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.formData.email_address.$dirty) return errors;
      !this.$v.formData.email_address.email &&
        errors.push("Must be valid e-mail");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.formData.driver_name.$dirty) return errors;
      !this.$v.formData.driver_name.nameChecker &&
        errors.push("Name should be valid");
      return errors;
    },
  },
  created() {
    this.countryDataSet = Country.getAllCountries();
    this.setDefaultCountry();
    if (this.show && this.isEdit) {
      this.setEditFormData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.v-dialog > * {
  background: #ffffff !important;
  min-height: 460px;
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.footer-section {
  width: 100%;
}
</style>
