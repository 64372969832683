<template>
  <div class="logistic-table-container pl-7 pr-7 width-100">
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="totalDrivers === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="driverHeaders"
        :items="drivers"
        :expanded.sync="expanded"
        hide-default-footer
        show-expand
        item-key="id"
        @update:page="updatePagination($event)"
        :server-items-length="totalDrivers"
        :options="{ page: page, itemsPerPage: limit }"
        @update:items-per-page="updatePerPage($event)"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
          />
        </template>
        <template v-slot:item.driver_name="{ item }">
          <div class="display-flex align-center">
            <Avatar
              v-if="item.driver_image"
              :url="`${serverUrl}/${item.driver_image}`"
              :name="item.driver_name"
              size="50"
              radius="12"
            />
            <Avatar v-else :name="item.driver_name" size="50" radius="12" />
            <div class="name ml-4">
              {{ item.driver_name ? item.driver_name : "-" }}
            </div>
          </div>
        </template>

        <template v-slot:item.contact_number="{ item }">
          <div class="name">
            {{ item.contact_number ? item.contact_number : "-" }}
          </div>
        </template>

        <template v-slot:item.address="{ item }">
          <div class="address-1" v-if="item.address">
            {{ item.block_number ? item.block_number + ", " : ""
            }}{{ item.floor_number ? item.floor_number + ", " : ""
            }}{{ item.address }}
          </div>
          <div class="address-1" v-if="item.address">
            {{ item.cityortown ? item.cityortown + ", " : ""
            }}{{ item.state ? item.state + ", " : ""
            }}{{ item.country ? item.country : "" }}
          </div>
          <div class="address-1" v-else>-</div>
        </template>

        <template v-slot:item.license_number="{ item }">
          <div class="name">
            {{ item.license_number ? item.license_number : "-" }}
          </div>
        </template>

        <template v-slot:item.license_type="{ item }">
          <div class="name">
            {{ item.license_type ? item.license_type : "-" }}
          </div>
        </template>

        <template v-slot:item.email_address="{ item }">
          <div class="name">
            {{ item.email_address ? item.email_address : "-" }}
          </div>
        </template>

        <template v-slot:item.actions="props">
          <div
            class="action-buttons display-flex align-center"
            v-if="props.item.actions"
          >
            <ActionButton
              label="Deliveries"
              :count="props.item.ordercount"
              icon="delivery"
              width="130px"
              class="mr-4"
              @click="expandClicked(props, 'Deliveries', props.item.type)"
              :active="props.item.deliverySelected"
            />
            <v-edit-dialog
              v-if="loggedUserPermission.edit_drivers"
              :return-value.sync="props.item.action"
            >
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @editClicked="editClicked(props.item)"
                  @deleteClicked="deleteClicked(props.item)"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="inner-table-td disply-flex" :colspan="headers.length">
            <div>
              <div
                class="inner-table-header display-flex justify-space-between align-center"
              >
                <div class="display-flex align-center">
                  <img src="@/assets/icons/delivery.svg" class="mb-1" />
                  <div class="table-title ml-2">Deliveries</div>
                  <SearchInput
                    :value="item.searchValue"
                    :autofocus="true"
                    class="ml-4"
                    @change="
                      onSearch($event, {
                        type: 'Deliveries',
                        driver: item,
                      })
                    "
                  />
                  <!-- Filter Start -->
                  <v-edit-dialog v-if="!item.hideFilter" ref="driverFilterModal">
                    <v-btn class="ml-4 position-relative" icon dark>
                      <img src="@/assets/icons/filter.svg" />
                      <div
                        v-if="isDeliveryFilter"
                        class="filter-marker position-absolute"
                      ></div>
                    </v-btn>
                    <template v-slot:input>
                      <DriverFilter
                        :key="driverFilterKey"
                        @onClose="
                          filterClicked({
                            type: 'Deliveries',
                            driver: item,
                            isFilter: false,
                          })
                        "
                        @onFilter="
                          filterClicked({
                            type: 'Deliveries',
                            driver: item,
                            isFilter: true,
                          })
                        "
                      />
                    </template>
                  </v-edit-dialog>
                  <!-- Filter End -->
                  <Button
                    label="Export"
                    type="secondary"
                    width="137px"
                    class="ml-4"
                    @click="exportDelivery(item)"
                  />
                </div>
              </div>
            </div>
            <Spinner
              class="inner-spinner-class exact-center"
              v-if="isTableLoading"
            />
            <InnerTable
              v-else
              :item="item"
              :selectedType="item.type"
              :headers="item.innerHeaders"
              :items="item.innerItems"
              :key="innerTableKey"
              :totalItem="item.totalItem"
              :page="item.page"
              :isFilter="item.isFilter"
              :searchValue="item.searchValue"
              @updatePagination="updateInnerTablePage"
            />
          </td>
        </template>
      </v-data-table>
    </div>
    <AddDriver
      title="Edit Driver"
      :show="showAddDriver"
      @close="showAddDriver = false"
      width="928"
      :isEdit="true"
      :item="selectedItem"
      @saveClicked="refresh"
      :key="addKey"
    />
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Driver`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the selected Driver ? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
  </div>
</template>

<script>
import InnerTable from "@/components/Logistics/Drivers/InnerTable";
import AddDriver from "@/components/Logistics/Drivers/AddDriver";
import DriverFilter from "./DriverFilter.vue";
import { mapGetters, mapActions } from "vuex";
import { SERVER_URL, ITEMS_PER_PAGE } from "../../../../constants";

export default {
  name: "LogisticsDriversTable",
  components: { InnerTable, AddDriver, DriverFilter },
  data() {
    return {
      serverUrl: SERVER_URL,
      innerTableKey: 20,
      itemsPerPage: ITEMS_PER_PAGE,
      isLoading: false,
      deleteDialog: false,
      showAddDriver: false,
      isTableLoading: false,
      expanded: [],
      driverHeaders: [
        {
          text: "Driver Name",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "contact_number",
        },
        {
          text: "License Type",
          align: "start",
          sortable: false,
          value: "license_type",
          width: "140px",
        },
        {
          text: "License No.",
          align: "start",
          sortable: false,
          value: "license_number",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email_address",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
      deliveryHeaders: [
        {
          text: "Tracking Id",
          align: "start",
          sortable: false,
          value: "tracking_id",
          width: "200px",
        },
        {
          text: "Delivery Type",
          align: "start",
          sortable: false,
          value: "delivery_type",
          width: "180px",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
          width: "200px",
        },
        {
          text: "From",
          align: "start",
          sortable: false,
          value: "from",
          width: "250px",
        },
        {
          text: "To",
          align: "start",
          sortable: false,
          value: "to",
          width: "250px",
        },
        {
          text: "Pickup date",
          align: "start",
          sortable: false,
          value: "pickup_date",
          width: "180px",
        },
        {
          text: "Est Delivery Date",
          align: "start",
          sortable: false,
          value: "est_del_date",
          width: "200px",
        },
        {
          text: "Delivered Date",
          align: "start",
          sortable: false,
          value: "delivered_at",
          width: "200px",
        },
      ],
      selectedItem: {},
      addKey: 10,
      driverFilterKey: 30,
    };
  },
  computed: {
    ...mapGetters("logistics/driver", [
      "drivers",
      "totalDrivers",
      "page",
      "limit",
      "deliveries",
      "deliveriesCount",
      "deliveriesPage",
      "deliveryFromDate",
      "deliveryToDate",
      "isDeliveryFilter",
      "fromDate",
      "toDate",
      "type",
    ]),
    ...mapGetters("logistics/authentication", ["loggedUserPermission"]),
  },
  methods: {
    ...mapActions({
      getDrivers: "logistics/driver/getDrivers",
      exportDeliveries: "logistics/driver/exportDeliveries",
      setPage: "logistics/driver/setPage",
      setLimit: "logistics/driver/setLimit",
      deleteDriver: "logistics/driver/deleteDriver",
      fetchDeliveries: "logistics/driver/fetchDeliveries",
      setDeliveriesPage: "logistics/driver/setDeliveriesPage",
      setDeliveriesSearchQuery: "logistics/driver/setDeliveriesSearchQuery",
      setDeliveriesFilterValues: "logistics/driver/setDeliveriesFilterValues",
      setDeliveriesDateRange: "logistics/driver/setDeliveriesDateRange",
    }),
    async exportDelivery(item) {
      await this.exportDeliveries(item?.id);
    },
    async expandClicked(slotData, type, selectedType) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1 && selectedType === type) {
        this.expanded.splice(indexExpanded, 1);
        this.drivers.map((item) => {
          if (item.name === slotData.item.name) {
            item.deliverySelected = false;
            item.selected = false;
          }
        });
      } else {
        this.expanded = [];
        this.expanded.push(slotData.item);
        this.drivers.map(async (item) => {
          if (item.id === slotData.item.id) {
            item.deliverySelected = true;
            item.selected = true;
            this.setDeliveriesSearchQuery("");
            let hideFilter = false;
            //If Scheduled date is mentioned in main filter - Inner filter is based on that
            if (this.type === "scheduled_date") {
              this.setDeliveriesFilterValues({
                dateRange: { fromDate: this.fromDate, toDate: this.toDate },
                type: "scheduled_date",
              });
              hideFilter = true;
            } else {
              this.setDeliveriesFilterValues(null);
              hideFilter = false;
            }
            this.driverFilterKey += 1;
            this.isTableLoading = true;
            await this.fetchDeliveries(slotData.item.id);
            item.hideFilter = hideFilter;
            this.isTableLoading = false;
            item.innerHeaders = this.deliveryHeaders;
            item.innerItems = this.deliveries;
            item.page = this.deliveriesPage;
            item.totalItem = this.deliveriesCount;
            item.type = type;
            item.searchValue = "";
            this.innerTableKey += 1;
          } else {
            item.deliverySelected = false;
            item.selected = false;
          }
        });
      }
    },
    async updateInnerTablePage(selectedItem) {
      this.drivers.map(async (item) => {
        if (item.id === selectedItem?.driver?.id) {
          switch (selectedItem.type) {
            case "Deliveries":
              this.setDeliveriesSearchQuery("");
              this.setDeliveriesPage(selectedItem.page);
              this.isTableLoading = true;
              await this.fetchDeliveries(item.id);
              this.isTableLoading = false;
              item.innerItems = this.deliveries;
              item.page = this.deliveriesPage;
              item.totalItem = this.deliveriesCount;
              item.searchValue = "";
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async filterClicked(selectedItem) {
      if (!selectedItem.isFilter) {
        this.driverFilterKey += 1;
      }
      this.$refs["driverFilterModal"].isActive = false;
      this.drivers.map(async (item) => {
        if (item.id === selectedItem?.driver?.id) {
          switch (selectedItem.type) {
            case "Deliveries":
              this.setDeliveriesPage(1);
              this.isTableLoading = true;
              await this.fetchDeliveries(item.id);
              this.isTableLoading = false;
              item.innerItems = this.deliveries;
              item.page = this.deliveriesPage;
              item.totalItem = this.deliveriesCount;
              item.isFilter = this.isDeliveryFilter;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    async onSearch(value, selectedItem) {
      this.drivers.map(async (item) => {
        if (item.id === selectedItem?.driver?.id) {
          switch (selectedItem.type) {
            case "Deliveries":
              this.setDeliveriesSearchQuery(value);
              this.setDeliveriesPage(1);
              this.isTableLoading = true;
              await this.fetchDeliveries(item.id);
              this.isTableLoading = false;
              item.innerItems = this.deliveries;
              item.page = this.deliveriesPage;
              item.totalItem = this.deliveriesCount;
              item.searchValue = value;
              this.innerTableKey += 1;
              break;
          }
        }
      });
    },
    editClicked(item) {
      this.selectedItem = item;
      this.addKey += 1;
      this.showAddDriver = true;
    },
    deleteClicked(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.selectedItem = {};
      this.deleteDialog = false;
    },
    async onDeleteConfirm() {
      try {
        await this.deleteDriver(this.selectedItem);
        await this.fetchDrivers();
        this.selectedItem = {};
        this.deleteDialog = false;
      } catch (error) {
        console.log(error);
        this.deleteDialog = false;
      }
    },
    async updatePagination(value) {
      this.expanded = [];
      this.setPage(value);
      await this.fetchDrivers();
    },
    async updatePerPage(value) {
      this.expanded = [];
      this.setLimit(value);
      await this.fetchDrivers();
    },
    async fetchDrivers() {
      this.isLoading = true;
      await this.getDrivers();
      this.isLoading = false;
    },
    async refresh() {
      this.showAddDriver = false;
      await this.fetchDrivers();
      this.selectedItem = {};
    },
  },
  async created() {
    this.expanded = [];
    this.setDeliveriesSearchQuery("");
    this.setDeliveriesPage(1);
    await this.fetchDrivers();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.inner-table-header {
  margin-bottom: 2.5rem;
  .table-title {
    font-weight: 600;
    font-size: 1rem;
    color: $secondary-color;
  }
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td
  > .v-icon {
  display: none !important;
}
::v-deep .v-data-table > .v-data-footer {
  border: none;
}
.filter-marker {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: $primary-color;
  top: -2px;
  right: -3px;
}
.inner-table-td {
  background: $white-color !important;
}

.logistic-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
}
</style>
