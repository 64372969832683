<template>
  <div class="delivery-type-card">
    <div
      class="header dispaly-flex justify-space-between width-100 align-center"
    >
      <h1 class="mb-4">Delivery Type</h1>
      <div class="header-slot justify-end">
        <div class="dropdown-width">
          <v-select
            @change="onSelectChange"
            :items="filterList"
            v-model="selecedFilter"
          />
        </div>
      </div>
    </div>
    <div class="width-100 position-relative">
      <highcharts
        :key="graphKey"
        :options="chartOptions"
        :highcharts="hcInstance"
      ></highcharts>
      <div
        class="total-count display-flex flex-col exact-center width-100 position-absolute"
      >
        <SkeletonLoader
          width="30"
          height="20"
          type="card"
          class="mb-2"
          v-if="loading"
        />
        <div class="count" v-else>
          {{ deliveryTypeData ? deliveryTypeData.total : 0 }}
        </div>
        <h3>Total Delivery</h3>
      </div>
    </div>
    <div class="legend-container exact-center width-100 mt-4">
      <v-radio-group v-model="group1" class="mr-2">
        <v-radio label="Direct Delivery" :value="1" color="#FF003F"></v-radio>
      </v-radio-group>
      <v-radio-group v-model="group2" class="mr-2">
        <v-radio label="Sorting Center" :value="1" color="#BCEA5B"></v-radio>
      </v-radio-group>
      <v-radio-group v-model="group3">
        <v-radio label="Unassigned" :value="1" color="#1C2162"></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Highcharts from "highcharts";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LogisticOrdersGraphCard",
  data() {
    return {
      loading: false,
      graphKey: 0,
      group1: 1,
      group2: 1,
      group3: 1,
      hcInstance: Highcharts,
      filterList: [
        "Today",
        "This Week",
        "Last Week",
        "This Month",
        "Last Month",
      ],
      selecedFilter: "This Week",
      chartOptions: {
        chart: {
          type: "variablepie",
          height: 270,
          // width: 300,
        },
        colors: ["#F64E60", "#BCEA5B", "#1C2162"],
        title: "",
        tooltip: {
          enabled: true,
          pointFormat: "{point.y}",
        },
        labels: {
          enabled: false,
        },
        plotOptions: {
          variablepie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: "70%",
            zMin: 0,
            data: [
              {
                name: "Direct Delivery",
                y: 0,
                z: 20,
              },
              {
                name: "Sorting Center",
                y: 0,
                z: 20,
              },
              {
                name: "Unassigned Delivery",
                y: 0,
                z: 20,
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      getDeliveryTypeData: "logistics/dashboard/getDeliveryTypeData",
      setDate: "logistics/dashboard/setDate",
    }),
    setGraphData() {
      this.chartOptions.series[0].data[0].y = this.deliveryTypeData?.directDelivery;
      this.chartOptions.series[0].data[1].y = this.deliveryTypeData?.sortinCenterDelivery;
      this.chartOptions.series[0].data[2].y = this.deliveryTypeData?.unAssignedDelivery;
    },
    async onSelectChange(value) {
      this.loading = true;
      switch (value) {
        case "Today":
          this.fromDate = moment()
            .startOf("day")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("day")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "This Week":
          this.fromDate = moment()
            .startOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "This Month":
          this.fromDate = moment()
            .startOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "Last Week":
          this.fromDate = moment()
            .subtract(1, "weeks")
            .startOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .subtract(1, "weeks")
            .endOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "Last Month":
          this.fromDate = moment()
            .subtract(1, "months")
            .startOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .subtract(1, "months")
            .endOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        default:
          this.fromDate = "";
          this.toDate = "";
      }
      this.setDate({ fromDate: this.fromDate, toDate: this.toDate });
      await this.getDeliveryTypeData();
      this.setGraphData();
      this.graphKey += 1;
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters("logistics/dashboard", ["deliveryTypeData"]),
  },
  async created() {
    this.loading = true;
    this.onSelectChange("This Week");
    await this.getDeliveryTypeData();
    this.setGraphData();
    this.graphKey += 1;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.header-slot {
  color: rgba(13, 24, 66, 0.5);
  font-size: 12px;
  .dropdown-width {
    width: 130px;
  }
  ::v-deep .v-input__slot::before {
    border-style: none !important;
  }
  ::v-deep .v-text-field {
    padding-top: unset !important;
    margin-top: unset !important;
  }
  ::v-deep .v-select__selection {
    color: rgba(13, 24, 66, 0.8) !important;
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
  ::v-deep .v-input__slot:after {
    border-style: none !important;
  }
}
::v-deep .highcharts-credits {
  display: none;
}
::v-deep .v-label {
  font-size: 10px;
  color: #999da0;
}
.delivery-type-card {
  height: 457px;
  background: $white-color;
  border: 1px solid #ebebeb;
  padding: 1.875rem;
  .header {
    h1 {
      font-weight: 500;
      font-size: 1.125rem;
    }
  }
  .total-count {
    top: 6.5rem;
    .count {
      font-size: 1.25rem;
      color: $secondary-color;
      font-weight: 600;
    }
    h3 {
      font-size: 1.125rem;
      color: $secondary-color;
      font-weight: 600;
    }
  }
}
</style>
