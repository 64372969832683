<template>
  <div class="request-inner-table-container width-100">
    <div
      class="inner-table-header display-flex justify-space-between align-center"
    >
      <div class="display-flex align-center">
        <div class="table-title ml-2">Assignments</div>
      </div>
      <!--   <div class="button-container">
        <Button
          label="+ Add Assignment"
          type="cancel"
          width="94px"
          @click="onAddAssignment"
        />
      </div> -->
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      hide-default-footer
    >
      <template v-slot:item.driver="{ item }">
        <div class="name">
          {{
            item.driverdetails.driver_name
              ? item.driverdetails.driver_name
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.est_del_date="{ item }">
        <div class="name">
          {{
            item.estimation_delivery_date && !item.delivered_at
              ? formatDate(item.estimation_delivery_date)
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.delivered_at="{ item }">
        <div class="name">
          {{ item.delivered_at ? formatDateTime(item.delivered_at) : "-" }}
        </div>
      </template>

      <template v-slot:item.pickup_date="{ item }">
        <div class="name">
          {{ item.picked_at ? formatDateTime(item.picked_at) : "-" }}
        </div>
      </template>

      <template v-slot:item.from="{ item }">
        <div class="address-1">
          {{
            item.fromAddressDetails.block_number
              ? item.fromAddressDetails.block_number + ", "
              : ""
          }}
          {{
            item.fromAddressDetails.floor_number
              ? item.fromAddressDetails.floor_number + ", "
              : ""
          }}{{
            item.fromAddressDetails.address
              ? item.fromAddressDetails.address
              : "-"
          }}
        </div>
        <div class="address-2" v-if="item.fromAddressDetails.address">
          {{
            item.fromAddressDetails.cityortown
              ? item.fromAddressDetails.cityortown + ", "
              : ""
          }}{{
            item.fromAddressDetails.state
              ? item.fromAddressDetails.state + ", "
              : ""
          }}{{
            item.fromAddressDetails.country
              ? item.fromAddressDetails.country
              : ""
          }}
        </div>
      </template>
      <template v-slot:item.to="{ item }">
        <div class="address-1">
          {{
            item.toAddressDetails.block_number
              ? item.toAddressDetails.block_number + ", "
              : ""
          }}
          {{
            item.toAddressDetails.floor_number
              ? item.toAddressDetails.floor_number + ", "
              : ""
          }}{{
            item.toAddressDetails.address ? item.toAddressDetails.address : "-"
          }}
        </div>
        <div class="address-2" v-if="item.toAddressDetails.address">
          {{
            item.toAddressDetails.cityortown
              ? item.toAddressDetails.cityortown + ", "
              : ""
          }}{{
            item.toAddressDetails.state
              ? item.toAddressDetails.state + ", "
              : ""
          }}{{
            item.toAddressDetails.country ? item.toAddressDetails.country : ""
          }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div
          class="name"
          :style="{
            color: '#387C17',
          }"
        >
          {{
            item.started_at && item.picked_at && item.delivered_at
              ? "Delivered"
              : item.started_at && item.picked_at
              ? "Order Picked"
              : item.started_at
              ? "Driver Enroute"
              : "Not yet Started"
          }}
        </div>
        <div
          v-if="item.status && item.status === 'Failed'"
          class="attempts mt-2"
        >
          No response
        </div>
      </template>
      <template v-slot:item.delivered_image="{ item }">
        <div class="name">
          <img
            :src="`${serverUrl}/${item.delivered_image}`"
            class="pod-image cursor-pointer"
            alt="pod-img"
            v-if="item.delivered_image"
            v-viewer="{
              button: true,
              navbar: false,
              title: false,
              toolbar: false,
              tooltip: false,
              movable: false,
              zoomable: false,
              rotatable: false,
              scalable: false,
              transition: true,
              fullscreen: false,
              keyboard: false,
            }"
          />
          <span v-else>-</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { SERVER_URL } from "../../../../constants";

export default {
  name: "LogisticRequestInnerTable",
  components: {},
  props: ["headers", "selectedType", "items"],
  data() {
    return {
      serverUrl: SERVER_URL,
    };
  },
  methods: {
    formatDateTime(date) {
      return moment(date).format("Do MMM, YYYY - hh:mm a");
    },
    formatDate(date) {
      return moment(date).format("Do MMM, YYYY ");
    },
    onAddAssignment() {},
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.request-inner-table-container {
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
    background: $white-color !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 18px;
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep
    .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td
    > .v-icon {
    display: none !important;
  }
  ::v-deep .v-data-table > .v-data-footer {
    border: none;
  }
  .pod-image {
    width: 44px;
    height: 57px;
    object-fit: cover;
  }
  background: $white-color !important;
  .inner-table-header {
    margin-bottom: 1.5rem;
    .table-title {
      font-weight: 600;
      font-size: 1rem;
      color: $secondary-color;
    }
  }
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: #000000;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .attempts {
    font-size: 14px;
    color: #000000;
  }
}
</style>
