<template>
  <div class="ml-7 pl-7 pt-6 mr-7 pl-7">
    <div class="settings-title">Settings</div>
    <v-row class="no-gutters">
      <v-col class="col-5">
        <Account />
        <Security />
      </v-col>
      <v-col class="ml-4">
        <Roles />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Account from "./Account.vue";
import Roles from "./Roles.vue";
import Security from "./Security.vue";
export default {
  name: "AdminSettings",
  components: {
    Account,
    Roles,
    Security,
  },
};
</script>

<style lang="scss" scoped>
.settings-title {
  color: #4a5571;
  font-weight: bold;
  font-size: 16px;
}
</style>
