<template>
  <div class="orders-graph-card">
    <div
      class="header dispaly-flex justify-space-between width-100 align-center"
    >
      <h1 class="mb-4">Orders</h1>
      <div class="display-flex">
        <v-radio-group v-model="group1" class="mr-4">
          <v-radio label="This Week" :value="1" color="#FF003F"></v-radio>
        </v-radio-group>
        <v-radio-group v-model="group2">
          <v-radio label="Last Week" :value="1" color="#BCEA5B"></v-radio>
        </v-radio-group>
      </div>
    </div>
    <div class="width-100">
      <Spinner class="spinner-class exact-center" size="30" v-if="isLoading" />
      <highcharts
        v-else
        class="mt-2"
        :options="chartOptions"
        :highcharts="hcInstance"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LogisticOrdersGraphCard",
  data() {
    return {
      group1: 1,
      group2: 1,
      isLoading: false,
      hcInstance: Highcharts,
      chartOptions: {
        chart: {
          borderColor: "#FFFFFF",
          type: "areaspline",
          plotBorderWidth: 0,
          height: "317px",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"],
          allowDecimals: false,
          title: {
            text: "",
          },
          lineWidth: 0,
          gridLineWidth: 1,
          gridLineColor: "#ffffff",
        },
        yAxis: {
          gridLineColor: "#f3f3f3",
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
          allowDecimals: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: "",
          pointFormat: " Orders {point.y}",
        },
        plotOptions: {
          spline: {
            marker: {
              enable: false,
            },
          },
          series: {
            fillColor: {
              stops: [
                [0, "transparent"],
                [2, "transparent"],
              ],
            },
          },
        },
        series: [
          {
            name: "This Week",
            color: "#FF003F",
            lineWidth: 4,
            data: [10, 20, 40, 5, 30, 40, 40],
          },
          {
            name: "Last Week",
            color: "#BCEA5B",
            lineWidth: 4,
            data: [20, 30, 20, 40, 20, 30, 40],
          },
        ],
      },
    };
  },
    methods: {
    ...mapActions({
      getWeekOrderData: "logistics/dashboard/getWeekOrderData",
    }),
    setGraphData() {
    this.chartOptions.series[0].data = this.weekOrderData.current;
    this.chartOptions.series[1].data = this.weekOrderData.last;
    },
  },
  computed: {
    ...mapGetters("logistics/dashboard", ["weekOrderData"]),
  },
  async created() {
    this.isLoading = true;
    await this.getWeekOrderData();
    this.setGraphData();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px;
  color: #999da0;
}
::v-deep .highcharts-credits {
  display: none;
}
.orders-graph-card {
  height: 457px;
  background: $white-color;
  border: 1px solid #ebebeb;
  padding: 1.875rem;
  .header {
    h1 {
      font-weight: 500;
      font-size: 1.125rem;
    }
  }
  .spinner-class {
    height: 300px;
  }
}
</style>
