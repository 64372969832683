<template>
  <div>
    <v-dialog :value="show" persistent :max-width="width">
      <div>
        <!-- title -->
        <div
          class="add-user-title pt-8 pr-6 pl-6 align-center justify-space-between"
        >
          {{ title }}
          <v-btn @click="onCancel" icon dark>
            <img src="@/assets/icons/close.svg" />
          </v-btn>
        </div>
        <!-- body -->
        <v-row class="no-gutters mt-4 mr-6">
          <v-row no-gutters>
            <v-col>
              <TextField label="Name" :disabled="true" :value="user_name"
            /></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <TextField label="Email" :disabled="true" :value="email_address"
            /></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <TextField label="Company" :disabled="true" :value="company_name"
            /></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <TextArea
                :required="true"
                label="Feedback"
                rows="5"
                :value="formData.feedback"
                @change="getFormData($event, 'feedback')"
            /></v-col>
          </v-row>
          <div class="position-relative" v-if="isNotification">
            <div class="notification">{{ notificationMessage }}</div>
          </div>
        </v-row>
        <!-- footer -->
        <v-row class="justify-end footer-section pr-6 pb-5">
          <div class="justify-end">
            <Button
              class="ml-3"
              label="Submit"
              type="save"
              width="94px"
              @click="onSave"
              :disabled="isSubmitDisabled() || isLoading"
              :loading="isLoading"
            />
          </div>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FeedbackForm",
  mixins: [validationMixin],
  validations: {
    formData: {
      user_name: {},
      email_address: {},
      feedback: { required },
    },
  },
  props: ["show", "width", "title"],
  data() {
    return {
      isLoading: false,
      isNotification: false,
      notificationMessage: "",
      notificationKey: 0,
      user_name: "",
      email_address: "",
      company_name: "",
      formData: {
        feedback: "",
      },
    };
  },
  methods: {
    ...mapActions({
      sendFeedback: "logistics/users/sendFeedback",
    }),
    onCancel() {
      this.$emit("close");
    },
    async onSave() {
      this.isLoading = true;
      await this.sendFeedback(this.formData);
      this.isLoading = false;
      this.formData.feedBack = "";
      this.notificationMessage = "Thank you! Your feedback has been submitted";
      this.isNotification = true;
      this.notificationKey += 1;
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    async getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
  },
  async created() {
    this.user_name = this.loggedUser?.user_name;
    this.email_address = this.loggedUser?.email_address;
    this.company_name = this.loggedUser?.company?.name;
  },
  computed: {
    ...mapGetters("logistics/authentication", [
      "loggedUser",
      "loggedUserPermission",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.notification {
  position: absolute;
  top: -19px;
  color: green;
}
.v-dialog > * {
  background: #ffffff !important;
  min-height: 550px;
  //   overflow-y: scroll;
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.footer-section {
  width: 100%;
}
</style>
