<template>
  <div class="sorting-centers-table-container pl-7 pr-7 width-100">
    <Spinner class="spinner-class exact-center" v-if="isLoading" />
    <div v-else>
      <div v-if="totalSortingCenter === 0" class="no-content-container">
        <NoContent />
      </div>
      <v-data-table
        v-else
        :headers="sortingCenterHeaders"
        :items="sortingCenters"
        hide-default-footer
        item-key="id"
        @update:page="updatePagination($event)"
        :server-items-length="totalSortingCenter"
        :options="{ page: page, itemsPerPage: limit }"
        @update:items-per-page="updatePerPage($event)"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            show-first-last-page
            :items-per-page-options="itemsPerPage"
          />
        </template>
        <template v-slot:item.sortingcenter_name="{ item }">
          <div class="name">
            {{ item.sortingcenter_name ? item.sortingcenter_name : "-" }}
          </div>
        </template>

        <template v-slot:item.contact_person="{ item }">
          <div class="name">
            {{ item.contact_person ? item.contact_person : "-" }}
          </div>
        </template>

        <template v-slot:item.contact_number="{ item }">
          <div class="name">
            {{ item.contact_number ? item.contact_number : "-" }}
          </div>
        </template>

        <template v-slot:item.address="{ item }">
          <div class="address-1">
            {{ item.block_number ? item.block_number + ", " : ""
            }}{{ item.floor_number ? item.floor_number + ", " : ""
            }}{{ item.address }}
          </div>
          <div class="address-2" v-if="item.address">
            {{ item.cityortown ? item.cityortown + ", " : ""
            }}{{ item.state ? item.state + ", " : ""
            }}{{ item.country ? item.country : "" }}
          </div>
        </template>

        <template v-slot:item.phone_number="{ item }">
          <div class="name">
            {{ item.phone_number ? item.phone_number : "-" }}
          </div>
        </template>

        <template v-slot:item.fax_number="{ item }">
          <div class="name">{{ item.fax_number ? item.fax_number : "-" }}</div>
        </template>

        <template v-slot:item.actions="props">
          <div class="action-buttons display-flex align-center">
            <v-edit-dialog
              v-if="loggedUserPermission.edit_sorting_center"
              :return-value.sync="props.item.action"
            >
              <div class="more-options exact-center ml-2 cursor-pointer">
                <img src="@/assets/icons/more-options.svg" alt="more-options" />
              </div>
              <template v-slot:input>
                <InnerMoreOptions
                  @editClicked="editClicked(props.item)"
                  @deleteClicked="deleteClicked(props.item)"
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>
      </v-data-table>
    </div>
    <AddSortingCenter
      title="Edit Sorting Center"
      :show="showEdit"
      :width="728"
      @close="modalCloseClicked"
      :key="addKey"
      :isEdit="true"
      :item="selectedItem"
      @saveClicked="refresh"
    />
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete Sorting Center`"
      cancel="Cancel"
      :content="`Are you sure you want to delete the Sorting Center ? `"
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
  </div>
</template>

<script>
import AddSortingCenter from "./AddSortingCenter.vue";
import { mapGetters, mapActions } from "vuex";
import { ITEMS_PER_PAGE } from "../../../../constants";
export default {
  name: "SortingCenterTableComponent",
  data() {
    return {
      isLoading: false,
      deleteDialog: false,
      showEdit: false,
      itemsPerPage: ITEMS_PER_PAGE,
      selectedItem: {},
      addKey: 10,
      sortingCenterHeaders: [
        {
          text: "Sorting Center",
          align: "start",
          sortable: false,
          value: "sortingcenter_name",
        },
        {
          text: "Contact Person",
          align: "start",
          sortable: false,
          value: "contact_person",
        },
        {
          text: "Mobile No.",
          align: "start",
          sortable: false,
          value: "contact_number",
        },
        {
          text: "Address",
          align: "start",
          sortable: false,
          value: "address",
        },
        {
          text: "Phone No.",
          align: "start",
          sortable: false,
          value: "phone_number",
        },
        {
          text: "Fax No.",
          align: "start",
          sortable: false,
          value: "fax_number",
          width: "150px",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  components: { AddSortingCenter },
  computed: {
    ...mapGetters("logistics/sortingCenter", [
      "sortingCenters",
      "totalSortingCenter",
      "page",
      "limit",
    ]),
    ...mapGetters("logistics/authentication", [
      "loggedUser",
      "loggedUserPermission",
    ]),
  },
  methods: {
    ...mapActions({
      getSortingCenters: "logistics/sortingCenter/getSortingCenters",
      setPage: "logistics/sortingCenter/setPage",
      setLimit: "logistics/sortingCenter/setLimit",
      deleteSortingCenter: "logistics/sortingCenter/deleteSortingCenter",
    }),
    modalCloseClicked() {
      this.selectedItem = {};
      this.showEdit = false;
    },
    editClicked(item) {
      this.selectedItem = item;
      this.addKey += 1;
      this.showEdit = true;
    },
    deleteClicked(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.selectedItem = {};
      this.deleteDialog = false;
    },
    async onDeleteConfirm() {
      try {
        await this.deleteSortingCenter(this.selectedItem);
        await this.fetchSortingCenters();
        this.selectedItem = {};
        this.deleteDialog = false;
      } catch (error) {
        console.log(error);
        this.deleteDialog = false;
      }
    },
    async refresh() {
      this.showEdit = false;
      await this.fetchSortingCenters();
      this.selectedItem = {};
    },
    async updatePagination(value) {
      this.setPage(value);
      await this.fetchSortingCenters();
    },
    async updatePerPage(value) {
      this.setLimit(value);
      await this.fetchSortingCenters();
    },
    async fetchSortingCenters() {
      this.isLoading = true;
      await this.getSortingCenters(this.loggedUser);
      this.isLoading = false;
    },
  },
  async created() {
    if (!this.loggedUserPermission.edit_sorting_center) {
      this.sortingCenterHeaders.splice(this.sortingCenterHeaders.length - 2, 1);
    }
    await this.fetchSortingCenters();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
  background: #f6f6f7 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 4px solid $white-color !important;
  padding: 18px;
}

::v-deep .v-data-table > .v-data-footer {
  border: none;
}

.inner-table-td {
  background: $white-color !important;
}

.sorting-centers-table-container {
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
}
</style>
