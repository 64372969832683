<template>
  <div class="filter-outer-container">
    <div
      class="add-user-title ml-6 pt-8 display-flex width-100 align-center justify-space-between"
    >
      Filter
    </div>
    <div class="sub-title mb-3 mt-4  pl-6">Request Information</div>
    <v-row class="no-gutters pl-6 pr-6">
      <v-col>
        <v-row no-gutters>
          <v-col>
            <SelectField
              label="Date Type"
              :value="formData.type"
              :data-set="['NA', ...typeDataSet]"
              @change="getFormData($event, 'type')"
            />
          </v-col>
          <v-col class="ml-3">
            <DateSelectField
              label="Date Range"
              :isDatePicker="true"
              :data-set="['NA', ...dateCreatedDataSet]"
              @change="getFormData($event, 'dateRange')"
              :disabled="!formData.type"
              :key="dateKey"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <SelectField
              label="Order Status"
              :value="formData.statusFilter"
              :data-set="['NA', ...orderStatusDataSet]"
              @change="getFormData($event, 'statusFilter')"
            />
          </v-col>
          <v-col class="ml-3"></v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- footer -->
    <v-row class="justify-end footer-section pb-5 mt-4">
      <div class="justify-end">
        <Button label="Reset" type="cancel" width="94px" @click="onCancel" />
        <Button
          class="ml-3"
          label="Filter"
          type="save"
          width="94px"
          @click="onFilterClicked"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";

export default {
  name: "DriverFilter",
  mixins: [validationMixin],
  validations: {
    formData: {},
  },
  props: [],
  data() {
    return {
      isLoading: false,
      markers: [],
      formData: {
        dateRange: "",
        statusFilter: "",
        type: "",
      },
      dateKey: 10,
      typeDataSet: [
        { name: "Created Date", key: "created_at" },
        { name: "Delivered Date", key: "delivered_at" },
        { name: "Scheduled Date", key: "scheduled_date" },
      ],
      dateCreatedDataSet: ["Today", "This Week", "This Month"],
      orderStatusDataSet: [
        { name: "Delivery Completed", key: "Deliveries Completed" },
        { name: "Delivery Pending", key: "Delivery Pending" },
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      setDeliveriesFilterValues: "logistics/driver/setDeliveriesFilterValues",
    }),
    onCancel() {
      this.setDeliveriesFilterValues(null);
      this.$emit("onClose");
    },
    onFilterClicked() {
      this.setDeliveriesFilterValues(this.formData);
      this.$emit("onFilter");
    },
    async getFormData(value, fieldName) {
      if (value === "NA") {
        if (fieldName === "type") {
          this.formData.dateRange = "";
          this.dateKey += 1;
        }
        this.formData[fieldName] = "";
        await this.onFilterClicked();
      }
      if (fieldName === "dateRange" && value?.value === "NA") {
        this.formData[fieldName] = "";
        await this.onFilterClicked();
      }
      if (fieldName === "statusFilter") {
        this.formData.statusFilter = value.key;
        return;
      }
      if (fieldName === "type") {
        this.formData.type = value.key;
        return;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.sub-title {
  font-weight: 500;
  font-size: 13px;
  color: #4a5571;
}
.filter-outer-container {
  width: 780px;
  min-height: 350px;
}
.add-user-title {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}
.footer-section {
  width: 100%;
}
.locate-in-map {
  color: #808080;
  font-size: 12px;
}
</style>
