var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-inner-table-container width-100"},[_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":("Delete " + _vm.selectedType),"cancel":"Cancel","content":("Are you sure you want to delete the selected " + _vm.selectedType + "? "),"accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"name","hide-default-footer":"","show-expand":"","server-items-length":_vm.totalItem,"options":{ page: _vm.page, itemsPerPage: 5 }},on:{"update:page":function($event){return _vm.updatePagination($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","show-first-last-page":""},on:{"update:options":updateOptions}})]}},{key:"item.tracking_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.tracking_id ? item.tracking_id : "-")+" ")])]}},{key:"item.delivery_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.delivery_type ? item.delivery_type : "-")+" ")])]}},{key:"item.est_del_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.estimation_delivery_date && !item.delivered_at ? _vm.formatDate(item.estimation_delivery_date) : "-")+" ")])]}},{key:"item.delivered_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.delivered_at ? _vm.formatDateTime(item.delivered_at) : "-")+" ")])]}},{key:"item.pickup_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.picked_at ? _vm.formatDateTime(item.picked_at) : "-")+" ")])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.fromAddressDetails.block_number ? item.fromAddressDetails.block_number + ", " : "")+" "+_vm._s(item.fromAddressDetails.floor_number ? item.fromAddressDetails.floor_number + ", " : "")+_vm._s(item.fromAddressDetails.address ? item.fromAddressDetails.address : "-")+" ")]),(item.fromAddressDetails.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.fromAddressDetails.cityortown ? item.fromAddressDetails.cityortown + ", " : "")+_vm._s(item.fromAddressDetails.state ? item.fromAddressDetails.state + ", " : "")+_vm._s(item.fromAddressDetails.country ? item.fromAddressDetails.country : "")+" ")]):_vm._e()]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.toAddressDetails.block_number ? item.toAddressDetails.block_number + ", " : "")+" "+_vm._s(item.toAddressDetails.floor_number ? item.toAddressDetails.floor_number + ", " : "")+_vm._s(item.toAddressDetails.address ? item.toAddressDetails.address : "-")+" ")]),(item.toAddressDetails.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.toAddressDetails.cityortown ? item.toAddressDetails.cityortown + ", " : "")+_vm._s(item.toAddressDetails.state ? item.toAddressDetails.state + ", " : "")+_vm._s(item.toAddressDetails.country ? item.toAddressDetails.country : "")+" ")]):_vm._e()]}},{key:"item.status",fn:function(props){return [_c('div',{staticClass:"name",style:({ color: _vm.orderStatus[props.item.status] })},[_vm._v(" "+_vm._s(props.item.status ? props.item.status : "-")+" ")])]}},{key:"item.delivered_image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[(item.delivered_image)?_c('img',{directives:[{name:"viewer",rawName:"v-viewer",value:({
            button: true,
            navbar: false,
            title: false,
            toolbar: false,
            tooltip: false,
            movable: false,
            zoomable: false,
            rotatable: false,
            scalable: false,
            transition: true,
            fullscreen: false,
            keyboard: false,
          }),expression:"{\n            button: true,\n            navbar: false,\n            title: false,\n            toolbar: false,\n            tooltip: false,\n            movable: false,\n            zoomable: false,\n            rotatable: false,\n            scalable: false,\n            transition: true,\n            fullscreen: false,\n            keyboard: false,\n          }"}],staticClass:"pod-image cursor-pointer",attrs:{"src":(_vm.serverUrl + "/" + (item.delivered_image)),"alt":"pod-img"}}):_c('span',[_vm._v("-")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }