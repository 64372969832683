<template>
  <div class="users-card">
    <h1 class="mb-6">Users</h1>
    <div class="details-container width-100 display-flex">
      <div
        class="detail display-flex align-center width-100 display-flex justify-space-between"
      >
        <SkeletonLoader v-if="loading" width="80" height="55" type="card" />
        <div v-else class="count display-flex flex-col">
          <h1>{{ count ? count : 0 }}</h1>
          <h2 class="mt-2 cursor-pointer" @click="viewClicked">View ></h2>
        </div>
        <div class="image ml-6 display-flex">
          <img
            src="@/assets/icons/dash-users1-img.svg"
            alt="dashboard-graph"
            height="60"
          />
          <img
            src="@/assets/icons/dash-users2-img.svg"
            alt="dashboard-graph"
            height="80"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogisticUserCard",
  props: ["count", "loading"],
  data() {
    return {};
  },
  methods: {
    viewClicked() {
      this.$router.push("users");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.users-card {
  height: 220px;
  background: $white-color;
  border: 1px solid #ebebeb;
  padding: 1.875rem;
  h1 {
    font-weight: 500;
    font-size: 1.125rem;
  }
  .details-container {
    h1 {
      font-weight: bold;
      font-size: 1.5rem;
    }
    h2 {
      font-size: 14px;
      color: #999da0;
    }
  }
}
</style>
