<template>
  <div class="order-summary-layout">
    <v-row class="no-gutters mt-4 chart-row pl-6 pr-6">
      <v-col class="col-3">
        <div class="header-title">Order Summary</div>
        <div class="overview">Overview</div>
        <img src="@/assets/icons/order-overview.svg" class="mt-6" />
        <div class="display-flex align-center mt-4 ml-3">
          <div class="red-mark"></div>
          <div class="ml-5">
            <span class="count">{{ orderSummary.totalOrder }}</span>
            <img class="mb-2" src="@/assets/icons/increment.svg" />
            <div class="today-order">Total Orders</div>
          </div>
        </div>
      </v-col>
      <v-col>
        <div class="header-slot justify-end">
          <div class="dropdown-width">
            <v-select
              @change="onSelectChange"
              :items="filterList"
              v-model="selecedFilter"
            />
          </div>
        </div>
        <Spinner
          size="25"
          v-if="isLoading"
          class="spinner-class exact-center"
        />
        <highcharts
          v-else
          class="mt-2"
          :options="chartOptions"
          :highcharts="hcInstance"
        ></highcharts>
      </v-col>
    </v-row>
    <v-row class="no-gutters order-status-container exact-center">
      <v-col class="display-flex order-status-col pl-2 pr-2">
        <img src="@/assets/icons/total-merchant.svg" />
        <div class="order-status ml-2">
          <div class="order-status-title">Order Unassigned</div>
          <div class="order-status-count">{{ orderSummary.unassigned }}</div>
        </div>
      </v-col>
      <v-col class="display-flex order-status-col pl-2 pr-2">
        <img src="@/assets/icons/total-merchant.svg" />
        <div class="order-status ml-2">
          <div class="order-status-title">Awaiting Pickup</div>
          <div class="order-status-count">
            {{ orderSummary.awaitingPickup }}
          </div>
        </div>
      </v-col>
      <v-col class="display-flex order-status-col pl-2 pr-2">
        <img src="@/assets/icons/order-dispatch.svg" />
        <div class="order-status ml-2">
          <div class="order-status-title">In Sorting Center</div>
          <div class="order-status-count">
            {{ orderSummary.inSortingCenter }}
          </div>
        </div>
      </v-col>
      <v-col class="display-flex order-status-col pl-2 pr-2">
        <img src="@/assets/icons/frame.svg" />
        <div class="order-status ml-2">
          <div class="order-status-title">Delivery in Progress</div>
          <div class="order-status-count">
            {{ orderSummary.deliveryInProgress }}
          </div>
        </div>
      </v-col>
      <v-col class="display-flex order-status-col pl-2 pr-2">
        <img src="@/assets/icons/order-delivered.svg" />
        <div class="order-status ml-2">
          <div class="order-status-title">Order Delivered</div>
          <div class="order-status-count">
            {{ orderSummary.deliveredCount }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "LogisticOrderSummary",
  data() {
    return {
      filterList: [
        "Today",
        "This Week",
        "Last Week",
        "This Month",
        "Last Month",
      ],
      selecedFilter: "This Week",
      hcInstance: Highcharts,
      chartOptions: {
        chart: {
          borderColor: "#FFFFFF",
          type: "areaspline",
          plotBorderWidth: 0,
          height: "300px",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: [],
          allowDecimals: false,
          title: {
            text: "",
          },
          lineWidth: 0,
          gridLineWidth: 1,
          gridLineColor: "#f3f3f3",
        },
        yAxis: {
          gridLineColor: "#f3f3f3",
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
          allowDecimals: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: "",
          pointFormat: " Orders {point.y}",
        },
        plotOptions: {
          spline: {
            marker: {
              enable: false,
            },
          },
          series: {
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "#f7cbc9"],
                [2, "#FFFFFF"],
              ],
            },
          },
        },
        series: [
          {
            name: "Days",
            color: "#eb453d",
            data: [],
          },
        ],
      },
      isLoading: false,
      fromDate: "",
      toDate: "",
    };
  },
  methods: {
    ...mapActions({
      getOrderSummary: "logistics/dashboard/getOrderSummary",
      setDate: "logistics/dashboard/setDate",
    }),
    async onSelectChange(value) {
      this.isLoading = true;
      switch (value) {
        case "Today":
          this.fromDate = moment()
            .startOf("day")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("day")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "This Week":
          this.fromDate = moment()
            .startOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "This Month":
          this.fromDate = moment()
            .startOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "Last Week":
          this.fromDate = moment()
            .subtract(1, "weeks")
            .startOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .subtract(1, "weeks")
            .endOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "Last Month":
          this.fromDate = moment()
            .subtract(1, "months")
            .startOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .subtract(1, "months")
            .endOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        default:
          this.fromDate = "";
          this.toDate = "";
      }
      this.setDate({ fromDate: this.fromDate, toDate: this.toDate });
      await this.getOrderSummary();
      this.chartOptions.xAxis.categories = this.orderSummary.xAxis;
      this.chartOptions.series[0].data = this.orderSummary.yAxis;
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters("logistics/dashboard", ["orderSummary"]),
  },
  async created() {
    this.isLoading = true;
    this.onSelectChange("This Week");
    await this.getOrderSummary();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.order-summary-layout {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  height: 522px;
  .chart-row {
    border-bottom: 1px solid #ebebeb;
  }
  .spinner-class {
    height: 320px;
  }
  .header-title {
    color: #000000;
    font-weight: 500;
    font-size: 18px;
  }
  .overview {
    color: #999da0;
    font-size: 14px;
  }
  .red-mark {
    background: #ff0000;
    width: 8px;
    height: 69px;
  }
  .count {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
  }
  .today-order {
    color: #999da0;
    font-size: 16px;
  }
}
::v-deep .highcharts-credits {
  display: none;
}
.header-slot {
  color: rgba(13, 24, 66, 0.5);
  font-size: 12px;
  .dropdown-width {
    width: 130px;
  }
  ::v-deep .v-input__slot::before {
    border-style: none !important;
  }
  ::v-deep .v-text-field {
    padding-top: unset !important;
    margin-top: unset !important;
  }
  ::v-deep .v-select__selection {
    color: rgba(13, 24, 66, 0.8) !important;
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
  ::v-deep .v-input__slot:after {
    border-style: none !important;
  }
}
.order-status {
  color: #000000;
  .order-status-title {
    font-weight: 500;
    font-size: 14px;
  }
  .order-status-count {
    font-weight: bold;
    font-size: 20px;
  }
}
.order-status-container {
  height: 145px;
  .order-status-col {
    border-right: 1px solid #ebebeb;
  }
}
</style>
