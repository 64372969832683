<template>
  <div>
    <div v-if="loggedUserPermission.management_dashboard" class="p-4">
      <v-row class="no-gutters">
        <v-col class="col-8">
          <OrderSummary />
          <v-row>
            <v-col class="col-6">
              <OrdersGraphCard />
            </v-col>
            <v-col class="col-6">
              <DeliveryTypeCard />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <UsersCard
                :key="userKey"
                :loading="isLoading"
                :count="resourceCounts.usersCount"
              />
            </v-col>
            <v-col class="col-6">
              <SortingCenterCard
                :key="sortingCenterKey"
                :loading="isLoading"
                :count="resourceCounts.sortingCenterCount"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-4">
          <TrackingCard />
        </v-col>
      </v-row>
    </div>
    <NoAccess v-else class="no-content-container" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TrackingCard from "./TrackingCard.vue";
import OrderSummary from "./OrderSummary.vue";
import UsersCard from "./UsersCard.vue";
import SortingCenterCard from "./SortingCenterCard.vue";
import OrdersGraphCard from "./OrdersGraphCard.vue";
import DeliveryTypeCard from "./DeliveryTypeCard.vue";

export default {
  name: "LogisticDashboard",
  data() {
    return {
      showAddOrder: false,
      isLoading: false,
      sortingCenterKey: 0,
      userKey: 10,
    };
  },
  computed: {
    ...mapGetters("logistics/dashboard", ["resourceCounts"]),
    ...mapGetters("logistics/authentication", ["loggedUserPermission"]),
  },
  components: {
    TrackingCard,
    OrderSummary,
    OrdersGraphCard,
    DeliveryTypeCard,
    UsersCard,
    SortingCenterCard,
  },
  methods: {
    ...mapActions({
      getResourceCounts: "logistics/dashboard/getResourceCounts",
    }),
    addOrder() {
      this.showAddOrder = true;
    },
    async fetchAllCounts() {
      this.isLoading = true;
      await this.getResourceCounts();
      this.warehouseKey += 1;
      this.isLoading = false;
    },
  },
  async created() {
    await this.fetchAllCounts();
  },
};
</script>
