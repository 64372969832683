<template>
  <div>
    <CommonToolbar
      title="Sorting Center"
      :primaryButtonLabel="
        loggedUserPermission.edit_sorting_center &&
          loggedUserPermission.view_sorting_center &&
          'Add Sorting Center'
      "
      :secondaryButtonLabel="
        loggedUserPermission.view_sorting_center && 'Export'
      "
      :isSearch="loggedUserPermission.view_sorting_center"
      @search="onSearch"
      @primaryButtonClick="addClick"
      @secondaryButtonClick="exportClick"
      @filterClicked="filterClicked"
      @resetClicked="resetClicked"
      :isFilter="fromDate && toDate"
      :dateFilterType="dateFilterType"
      :showDateFilter="loggedUserPermission.view_sorting_center"
    />
    <SubHeader :items="items" />
    <div class="sorting-center-table-container">
      <TableSection
        v-if="loggedUserPermission.view_sorting_center"
        :key="tableKey"
      />
      <NoAccess v-else class="no-content-container" />
      <div class="note-container width-100 justify-end pr-14 mt-2">
        <div class="note-card">
          <div>Note:</div>
          <div>If you dont have a Sorting Center, add your Store Address.</div>
        </div>
      </div>
    </div>
    <AddSortingCenter
      title="Add Sorting Center"
      :show="showAddSortingCenter"
      :width="728"
      :key="addKey"
      @saveClicked="refresh"
      @close="close"
    />
  </div>
</template>

<script>
import { LOGISTICS_SUB_NAV_ITEMS } from "../../../../constants";
import TableSection from "./TableSection";
import AddSortingCenter from "./AddSortingCenter.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LogisticSortingCenter",
  data() {
    return {
      show: false,
      items: LOGISTICS_SUB_NAV_ITEMS,
      showAddSortingCenter: false,
      tableKey: 0,
      addKey: 10,
      dateFilterType: [
        {
          name: "Created Date",
          value: "created_at",
        },
      ],
    };
  },
  components: { TableSection, AddSortingCenter },
  methods: {
    ...mapActions({
      setSearchQuery: "logistics/sortingCenter/setSearchQuery",
      exportSortingCenters: "logistics/sortingCenter/exportSortingCenters",
      setDateRange: "logistics/sortingCenter/setDateRange",
      getLogisticsTabCount: "logistics/count/getLogisticsTabCount",
      setPage: "logistics/sortingCenter/setPage",
    }),
    onSearch(value) {
      this.setPage(1);
      this.setSearchQuery(value);
      this.tableKey += 1;
    },
    refresh() {
      this.showAddSortingCenter = false;
      this.tableKey += 1;
      this.addKey += 1;
    },
    close() {
      this.showAddSortingCenter = false;
      this.addKey += 1;
    },
    addClick() {
      this.showAddSortingCenter = true;
    },
    async exportClick() {
      await this.exportSortingCenters();
    },
    filterClicked(value) {
      this.setDateRange(value);
      this.tableKey += 1;
    },
    resetClicked() {
      this.setDateRange({});
      this.tableKey += 1;
    },
  },
  async created() {
    this.setPage(1);
    this.setSearchQuery("");
    this.setDateRange({});
    await this.getLogisticsTabCount();
    this.items[0].count = this.count.requestCount;
    this.items[1].count = this.count.driverCount;
    this.items[2].count = this.count.sortingCenterCount;
  },
  watch: {
    totalSortingCenter() {
      this.items[2].count = this.totalSortingCenter;
    },
  },
  computed: {
    ...mapGetters("logistics/sortingCenter", [
      "fromDate",
      "toDate",
      "totalSortingCenter",
    ]),
    ...mapGetters("logistics/authentication", ["loggedUserPermission"]),
    ...mapGetters("logistics/count", ["count"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.sorting-center-table-container {
  background: $white-color;
  padding-bottom: 5.625rem;
}
.note-card {
  width: 378px;
  padding: 20px;
  background: #ffeecc;
  border-radius: 6px;
  div {
    color: $secondary-color;
    font-size: 12px;
  }
}
</style>
