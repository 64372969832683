<template>
  <div class="merchant-inner-table-container width-100">
    <ConfirmationDialog
      :dialog="deleteDialog"
      :title="`Delete ${selectedType}`"
      cancel="Cancel"
      :content="
        `Are you sure you want to delete the selected ${selectedType}? `
      "
      accept="Delete"
      @cancel="onDeleteCancel"
      @confirm="onDeleteConfirm"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      hide-default-footer
      show-expand
      :server-items-length="totalItem"
      @update:page="updatePagination($event)"
      :options="{ page: page, itemsPerPage: 5 }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          show-first-last-page
        />
      </template>
      <template v-slot:item.tracking_id="{ item }">
        <div class="name">
          {{ item.tracking_id ? item.tracking_id : "-" }}
        </div>
      </template>
      <template v-slot:item.delivery_type="{ item }">
        <div class="name">
          {{ item.delivery_type ? item.delivery_type : "-" }}
        </div>
      </template>

      <template v-slot:item.est_del_date="{ item }">
        <div class="name">
          {{
            item.estimation_delivery_date && !item.delivered_at
              ? formatDate(item.estimation_delivery_date)
              : "-"
          }}
        </div>
      </template>

      <template v-slot:item.delivered_at="{ item }">
        <div class="name">
          {{ item.delivered_at ? formatDateTime(item.delivered_at) : "-" }}
        </div>
      </template>

      <template v-slot:item.pickup_date="{ item }">
        <div class="name">
          {{ item.picked_at ? formatDateTime(item.picked_at) : "-" }}
        </div>
      </template>

      <template v-slot:item.from="{ item }">
        <div class="address-1">
          {{
            item.fromAddressDetails.block_number
              ? item.fromAddressDetails.block_number + ", "
              : ""
          }}
          {{
            item.fromAddressDetails.floor_number
              ? item.fromAddressDetails.floor_number + ", "
              : ""
          }}{{
            item.fromAddressDetails.address
              ? item.fromAddressDetails.address
              : "-"
          }}
        </div>
        <div class="address-2" v-if="item.fromAddressDetails.address">
          {{
            item.fromAddressDetails.cityortown
              ? item.fromAddressDetails.cityortown + ", "
              : ""
          }}{{
            item.fromAddressDetails.state
              ? item.fromAddressDetails.state + ", "
              : ""
          }}{{
            item.fromAddressDetails.country
              ? item.fromAddressDetails.country
              : ""
          }}
        </div>
      </template>
      <template v-slot:item.to="{ item }">
        <div class="address-1">
          {{
            item.toAddressDetails.block_number
              ? item.toAddressDetails.block_number + ", "
              : ""
          }}
          {{
            item.toAddressDetails.floor_number
              ? item.toAddressDetails.floor_number + ", "
              : ""
          }}{{
            item.toAddressDetails.address ? item.toAddressDetails.address : "-"
          }}
        </div>
        <div class="address-2" v-if="item.toAddressDetails.address">
          {{
            item.toAddressDetails.cityortown
              ? item.toAddressDetails.cityortown + ", "
              : ""
          }}{{
            item.toAddressDetails.state
              ? item.toAddressDetails.state + ", "
              : ""
          }}{{
            item.toAddressDetails.country ? item.toAddressDetails.country : ""
          }}
        </div>
      </template>
      <template v-slot:item.status="props">
        <div class="name" :style="{ color: orderStatus[props.item.status] }">
          {{ props.item.status ? props.item.status : "-" }}
        </div>
      </template>
      <template v-slot:item.delivered_image="{ item }">
        <div class="name">
          <img
            :src="`${serverUrl}/${item.delivered_image}`"
            class="pod-image cursor-pointer"
            alt="pod-img"
            v-if="item.delivered_image"
            v-viewer="{
              button: true,
              navbar: false,
              title: false,
              toolbar: false,
              tooltip: false,
              movable: false,
              zoomable: false,
              rotatable: false,
              scalable: false,
              transition: true,
              fullscreen: false,
              keyboard: false,
            }"
          />
          <span v-else>-</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ORDER_STATUS } from "../../../../constants";

export default {
  name: "DriverInnerTable",
  props: [
    "headers",
    "selectedType",
    "items",
    "totalItem",
    "page",
    "isFilter",
    "item",
  ],
  data() {
    return {
      orderStatus: ORDER_STATUS,
      deleteDialog: false,
    };
  },
  computed:{
    ...mapGetters("logistics/driver", ["isDeliveryFilter"])
  },
  methods: {
    ...mapActions({ exportDeliveries: "logistics/driver/exportDeliveries" }),
    onClickAddDriver() {},
    async exportDelivery() {
      await this.exportDeliveries(this.item?.id);
    },
    filterClicked(value) {
      this.$refs["driverFilterModal"].isActive = false;
      this.$emit("filterClicked", {
        value,
        type: this.selectedType,
        driver: this.item,
        isFilter: true,
      });
    },
    resetClicked() {
      this.$refs["driverFilterModal"].isActive = false;
      this.$emit("filterClicked", {
        value: {},
        type: this.selectedType,
        driver: this.item,
        isFilter: false,
      });
    },
    updatePagination(value) {
      const type = this.selectedType;
      const page = value;
      this.$emit("updatePagination", { type, page, driver: this.item });
    },
    onSearch(value) {
      this.$emit("onSearch", {
        value,
        type: this.selectedType,
        driver: this.item,
      });
    },
    formatDate(date) {
      return moment(date).format("Do MMM,YYYY");
    },
    formatDateTime(date) {
      return moment(date).format("Do MMM, YYYY - hh:mm a");
    },
    formatProductDate(date) {
      return moment(date).format("Do MMM,YY");
    },
    onDeleteCancel() {
      this.deleteDialog = false;
    },
    onDeleteConfirm() {
      this.deleteDialog = false;
    },
    deleteClicked() {
      this.deleteDialog = true;
    },
    setDateFilterType() {
      this.dateFilterType = [
        {
          name: "Created Date",
          value: "created_at",
        },
      ];
    },
  },
  created() {
    this.setDateFilterType();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.merchant-inner-table-container {
  ::v-deep .v-data-footer__select {
    display: none;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody {
    background: $white-color !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    border-bottom: none !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 18px;
    border-bottom: 1px solid #f6f6f7 !important;
  }
  ::v-deep
    .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td
    > .v-icon {
    display: none !important;
  }
  ::v-deep .v-data-table > .v-data-footer {
    border: none;
  }

  background: $white-color !important;
  .inner-table-header {
    margin-bottom: 2.5rem;
    .table-title {
      font-weight: 600;
      font-size: 1rem;
      color: $secondary-color;
    }
  }
  .name {
    font-weight: 600;
    font-size: 0.875rem;
    color: $secondary-color;
  }
  .address-1 {
    font-weight: 600;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .address-2 {
    font-weight: 300;
    font-size: 0.75rem;
    color: $secondary-color;
  }
  .role {
    width: 128px;
    height: 36px;
    border-radius: 31px;
    font-weight: 500;
    font-size: 12px;
  }
  .super-user {
    background: #ffede3;
    color: #e87d1b;
  }
  .normal-user {
    background: #eeefff;
    color: #1b25c6;
  }
}
</style>
