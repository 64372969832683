<template>
  <div class="accounts-container mt-4">
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <div class="header">
      <h1 class="p-4">Account</h1>
    </div>
    <div v-if="loggedUserPermission.view_edit_account" class="p-4 form-details">
      <h1 class="mb-6">Profile Name</h1>
      <div class="form-container name-container">
        <TextField
          label="Your Name"
          :required="true"
          v-model="user_name"
          :errorMessages="nameErrors"
          :error="nameErrors && nameErrors.length > 0"
          @input="$v.user_name.$touch()"
          @blur="$v.user_name.$touch()"
        />
      </div>
      <div class="display-flex">
        <div class="mr-16">
          <h2>Profile Photo</h2>
          <div class="profile-pic-container">
            <ProfileImagePicker
              :url="imgSrc"
              size="80"
              :name="user_name"
              radius="50"
              @fileData="getProfilePicture"
              @removePic="removeImage"
            />
          </div>
        </div>
        <!--   <div>
          <h2>Company Logo</h2>
          <div class="profile-pic-container">
            <ProfileImagePicker
              :url="companyImgSrc"
              size="80"
              :name="loggedUser.company ? loggedUser.company.name : ''"
              radius="50"
              @fileData="getCompanyPicture"
              @removePic="removeCompanyImage"
              :editDisable="!loggedUserPermission.edit_roles_and_permissions"
            />
          </div>
        </div> -->
      </div>
      <div
        class="edit-company-details cursor-pointer"
        @click="editLogiticsClicked"
        v-if="loggedUserPermission.edit_roles_and_permissions"
      >
        Edit Company Details >
      </div>

      <h3>
        This Account was created on
        {{ loggedUser.created_at ? formatDate(loggedUser.created_at) : "-" }}
      </h3>
      <div class="mt-3 pb-3 display-flex width-100 justify-end">
        <Button
          label="Update"
          type="primary"
          width="100px"
          @click="updateClicked"
          :disabled="isSubmitDisabled() || isLoading"
          :loading="isLoading"
        />
      </div>
    </div>
    <NoAccess v-else class="no-content-container" />
    <AddLogistics
      :width="928"
      :isEdit="true"
      :item="loggedUser.company"
      :show="showEditLogistic"
      @close="onLogisticCancel"
      title="Edit Company"
      @saveClicked="refresh"
      :key="addLogisticKey"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import AddLogistics from "./AddLogistics.vue";
import { SERVER_URL, NAME_REGEX } from "../../../../constants";
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
const nameChecker = helpers.regex("nameValidate", NAME_REGEX);
export default {
  name: "Account",
  mixins: [validationMixin],
  validations: {
    user_name: { required, nameChecker },
  },
  components: { AddLogistics },
  data() {
    return {
      imgSrc: "",
      companyImgSrc: "",
      dp: null,
      companyDp: null,
      user_name: "",
      serverUrl: SERVER_URL,
      isLoading: false,
      notificationMessage: "",
      isNotification: false,
      notificationKey: 0,
      showEditLogistic: false,
      addLogisticKey: 10,
    };
  },
  computed: {
    ...mapGetters("logistics/authentication", [
      "loggedUser",
      "loggedUserPermission",
    ]),
    nameErrors() {
      const errors = [];
      if (!this.$v.user_name.$dirty) return errors;
      !this.$v.user_name.nameChecker && errors.push("Name should be valid");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      updateCurrentUser: "logistics/authentication/updateCurrentUser",
      getLoggedUserData: "logistics/authentication/getLoggedUserData",
    }),
    async refresh() {
      this.showEditLogistic = false;
      this.addLogisticKey += 1;
      await this.getLoggedUserData();
      this.notificationMessage = "Company Details updated successfully!";
      this.isNotification = true;
      this.notificationKey += 1;
    },
    onLogisticSave() {
      this.showEditLogistic = false;
      this.addLogisticKey += 1;
    },
    onLogisticCancel() {
      this.showEditLogistic = false;
      this.addLogisticKey += 1;
    },
    editLogiticsClicked() {
      this.showEditLogistic = true;
      this.addLogisticKey += 1;
    },
    pickFile() {
      this.$refs.profile_pic_filedialog.click();
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    getCompanyPicture(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener("load", () => {
        this.companyDp = files[0];
        this.companyImgSrc = fr.result;
      });
    },
    removeCompanyImage() {
      this.companyImgSrc = null;
      this.companyDp = null;
    },
    getProfilePicture(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener("load", () => {
        this.dp = files[0];
        this.imgSrc = fr.result;
      });
    },
    removeImage() {
      this.imgSrc = null;
      this.dp = null;
    },
    async updateClicked() {
      this.isLoading = true;
      const data = new FormData();
      const companyData = new FormData();
      data.append("user_name", this.user_name);
      if (this.dp) data.append("picture", this.dp);
      data.append("user_img", this.dp ? this.dp.toString() : null);
      if (this.companyDp) companyData.append("picture", this.companyDp);
      companyData.append(
        "company_img",
        this.companyDp ? this.companyDp.toString() : null
      );
      try {
        await this.updateCurrentUser({
          user: data,
          company: { id: this.loggedUser?.company?.id, formData: companyData },
        });
        this.notificationMessage = "Profile data updated successfully!";
        this.isNotification = true;
        this.notificationKey += 1;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.notificationMessage = err.response.data.message;
        this.isNotification = true;
        this.notificationKey += 1;
      }
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    setLoggedUserData() {
      this.user_name = this.loggedUser?.user_name;
      this.imgSrc = this.loggedUser.user_img
        ? `${this.serverUrl}/${this.loggedUser.user_img}`
        : "";
      this.companyImgSrc = this.loggedUser?.company?.company_img
        ? `${this.serverUrl}/${this.loggedUser?.company?.company_img}`
        : "";
      this.companyDp = this.loggedUser?.company?.company_img
        ? this.loggedUser?.company?.company_img
        : null;
      this.dp = this.loggedUser.user_img ? this.loggedUser.user_img : null;
    },
  },
  created() {
    this.setLoggedUserData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.accounts-container {
  height: 510px;
  background: $white-color;
  border: 1px solid #e5e5e5;
  .header {
    border-bottom: 1px solid #e1e1e1;
    h1 {
      font-weight: 500;
      font-size: 1.25rem;
      color: $secondary-color;
    }
  }
  .edit-company-details {
    font-weight: 500;
    color: $primary-color;
    font-size: 14px;
  }
  .form-details {
    h1 {
      font-weight: 500;
      font-size: 1rem;
      color: $secondary-color;
    }
    .profile-pic-container {
      display: flex;
      align-items: center;
      margin: 1.5rem 0;
    }
    .profile-pic-button {
      width: 5.188rem;
      align-items: center;
      display: flex;
      padding: 0.5rem 1.125rem;
      border-radius: 6px;
      cursor: pointer;
      justify-content: center;
      margin: 0 0.5rem 0 2rem;
    }
    .change-button {
      background: #ff003f12;
      color: $primary-color;
    }
    .remove-button {
      background: #f8f9ff;
      color: #4a557180;
    }
    h3 {
      font-weight: 500;
      font-size: 0.75rem;
      color: #0d184280;
      margin-top: 1.938rem;
    }
  }
}
</style>
