var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableComponent",staticClass:"request-container pl-14 pr-14"},[(_vm.isLoading)?_c('Spinner',{staticClass:"spinner-class exact-center"}):_c('div',[(_vm.totalRequest === 0)?_c('div',{staticClass:"no-content-container"},[_c('NoContent')],1):_c('v-data-table',{attrs:{"headers":_vm.requestHeaders,"items":_vm.requests,"expanded":_vm.expanded,"item-key":"id","item-class":_vm.itemRowBackground,"hide-default-footer":"","server-items-length":_vm.totalRequest,"options":{ page: _vm.page, itemsPerPage: _vm.limit }},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){return _vm.updatePagination($event)},"update:items-per-page":function($event){return _vm.updatePerPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","show-first-last-page":"","items-per-page-options":_vm.itemsPerPage},on:{"update:options":updateOptions}})]}},{key:"item.tracking_id",fn:function(props){return [_c('div',{staticClass:"display-flex align-center"},[_c('div',{staticClass:"expand-icon mr-4 cursor-pointer",on:{"click":function($event){return _vm.expandClicked(props, 'Assignment', props.item.type)}}},[(!props.item.assignmentSelected)?_c('img',{attrs:{"src":require("@/assets/icons/table-expansion-closed.svg"),"alt":"expand-icon"}}):_c('img',{attrs:{"src":require("@/assets/icons/table-expansion-open.svg"),"alt":"expand-icon"}})]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(props.item.tracking_id ? props.item.tracking_id : "-")+" ")])])]}},{key:"item.pickup_address",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.warehousedetails.block_number ? item.warehousedetails.block_number + "," : "")+_vm._s(item.warehousedetails.floor_number ? item.warehousedetails.floor_number + "," : "-")+_vm._s(item.warehousedetails ? item.warehousedetails.address : "-")+" ")]),(item.warehousedetails.address)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.warehousedetails.cityortown ? item.warehousedetails.cityortown + ", " : "")+_vm._s(item.warehousedetails.state ? item.warehousedetails.state + ", " : "")+_vm._s(item.warehousedetails.country ? item.warehousedetails.country : "")+" ")]):_vm._e()])]}},{key:"item.delivery_address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"address-1"},[_vm._v(" "+_vm._s(item.customerdetails.block_number ? item.customerdetails.block_number + "," : "")+_vm._s(item.customerdetails.floor_number ? item.customerdetails.floor_number + "," : "-")+_vm._s(item.customerdetails ? item.customerdetails.address : "-")+" ")]),(item.customerdetails)?_c('div',{staticClass:"address-2"},[_vm._v(" "+_vm._s(item.customerdetails.cityortown ? item.customerdetails.cityortown + ", " : "")+_vm._s(item.customerdetails.state ? item.customerdetails.state + ", " : "")+_vm._s(item.customerdetails.country ? item.customerdetails.country : "")+" ")]):_vm._e()]}},{key:"item.product_quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.ordertrackingdetails.product_quantity ? item.ordertrackingdetails.product_quantity : "-")+" ")])]}},{key:"item.uom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.ordertrackingdetails.uom ? item.ordertrackingdetails.uom : "-")+" ")])]}},{key:"item.expected_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.ordertrackingdetails.expected_delivery_date ? _vm.formatProductDate( item.ordertrackingdetails.expected_delivery_date ) : "-")+" ")])]}},{key:"item.pickup_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.pickup_date ? _vm.formatProductDate(item.pickup_date) : "-")+" ")])]}},{key:"item.est_del_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.estimation_delivery_date && item.status !== "Order delivered" ? _vm.formatProductDate(item.estimation_delivery_date) : "-")+" ")])]}},{key:"item.assigned_driver",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.driverdetails ? item.driverdetails.driver_name : "-")+" ")])]}},{key:"item.delivery_type",fn:function(ref){
var item = ref.item;
return [(
            item.delivery_type && item.status !== 'Parcel at sorting center'
          )?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.delivery_type)+" ")]):_c('v-edit-dialog',{ref:'assignDriver' + item.id,attrs:{"return-value":item.delivery_type,"persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "delivery_type", $event)},"update:return-value":function($event){return _vm.$set(item, "delivery_type", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('AssignDriverModal',{key:_vm.assignedKey,attrs:{"type":item.status,"id":item.id,"item":item},on:{"closeClicked":function($event){return _vm.closeAssignModal(item.id)},"refresh":_vm.refresh}})]},proxy:true}],null,true)},[_c('div',{staticClass:"name align-center display-flex"},[_c('div',{style:({
                color:
                  item.status === 'Parcel ready for shipping'
                    ? '#D2854C'
                    : '#D24C8D',
              })},[_vm._v(" Unassigned ")]),(item.status === 'Parcel ready for shipping')?_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/pencil-rfs.svg"),"alt":"request-assign"}}):_vm._e(),(item.status === 'Parcel at sorting center')?_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/pencil-iisc.svg"),"alt":"request-assign"}}):_vm._e(),(
                _vm.getDateforSameCondition(item) ||
                  _vm.getDateforAfterCondition(item)
              )?_c('img',{staticClass:"alert",attrs:{"src":require("@/assets/icons/alert.png"),"alt":"alert"}}):_vm._e()])])]}},{key:"item.status",fn:function(props){return [_c('div',{staticClass:"name",style:({ color: _vm.orderStatus[props.item.status] })},[_vm._v(" "+_vm._s(props.item.status ? props.item.status : "-")+" ")])]}},{key:"item.merchantcompany",fn:function(props){return [_c('div',{staticClass:"logistic-badge unassigned exact-center"},[_vm._v(" "+_vm._s(props.item.merchantcompany && props.item.merchantcompany.name ? props.item.merchantcompany.name : "-")+" ")])]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"inner-table-td disply-flex",attrs:{"colspan":headers.length}},[(_vm.isTableLoading)?_c('Spinner',{staticClass:"inner-spinner-class exact-center"}):_c('InnerTable',{key:_vm.innerTableKey,attrs:{"item":item,"selectedType":item.type,"headers":item.innerHeaders,"items":item.innerItems}})],1)]}},{key:"item.actions",fn:function(props){return [(props.item.actions)?_c('div',{staticClass:"action-buttons display-flex align-center"},[_c('v-edit-dialog',{attrs:{"return-value":props.item.action},on:{"update:returnValue":function($event){return _vm.$set(props.item, "action", $event)},"update:return-value":function($event){return _vm.$set(props.item, "action", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('InnerMoreOptions',{on:{"deleteClicked":_vm.deleteClicked}})]},proxy:true}],null,true)},[_c('div',{staticClass:"more-options exact-center ml-2 cursor-pointer"},[_c('img',{attrs:{"src":require("@/assets/icons/more-options.svg"),"alt":"more-options"}})])])],1):_vm._e()]}}])})],1),_c('ConfirmationDialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete Request","cancel":"Cancel","content":"Are you sure you want to delete the selected Request ? ","accept":"Delete"},on:{"cancel":_vm.onDeleteCancel,"confirm":_vm.onDeleteConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }